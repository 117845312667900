<template>
  <div>
    <router-view v-if="isLogin" />
  </div>
</template>
<script>
import { ref } from "vue";
import { loginByFeishu } from "./http/api";
const getQueryString = (name) => {
  var reg = new RegExp("(^|&)" + name + "=([^&]*)(&|$)", "i");
  var u = decodeURIComponent(window.location.search).substr(1).match(reg);
  if (u != null) {
    return unescape(u[2]);
  }
  return null;
};
export default {
  setup() {
    const isLogin = ref(true);
    const code = getQueryString("code") || "";
    if (!code) {
      if (window.location.hostname !== "localhost") {
        let url = "https://giga-admin.xiaote.net";
        url = encodeURIComponent(url);
        window.location.href = `https://open.feishu.cn/open-apis/authen/v1/index?app_id=cli_a4d22b0513b8100e&redirect_uri=${url}`;
      }
    } else {
      localStorage.setItem("code", code);
      loginByFeishu({ variables: { code } }).then((res) => {
        let { sessionToken, lcSessionToken } = res.data.loginByFeishu;
        localStorage.setItem("sessionToken", sessionToken);
        localStorage.setItem("lcSessionToken", lcSessionToken);
        isLogin.value = true;
      });
    }
    return {
      isLogin,
    };
  },
};
</script>
<style lang="scss">
@import url(./global.scss);
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
}
</style>
