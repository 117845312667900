<template>
  <div>
    <div class="operations">
      <div style="flex:1"></div>
      <a-button @click="toAdd">添加</a-button>
    </div>
    <GigaTable :gData="dataSource" :gColumns="columns" />
  </div>
</template>
<script>
import { getArticles, editArticle } from '../http/api'
import GigaTable from '../components/GigaTable.vue'
export default {
  components: {
    GigaTable,
  },
  data() {
    return {
      formState: {},
      dataSource: [],
      columns: [{
        title: 'id',
        dataIndex: 'id',
        key: 'id',
        width: 80
      }, {
        title: '标题',
        dataIndex: 'title',
        key: 'title'
      }, {
        title: '排序',
        dataIndex: 'displayOrder',
        key: 'displayOrder'
      }, {
        title: '是否发布',
        dataIndex: 'isPublished',
        key: 'isPublished',
        customRender: val => <span v-html={val.value ? '已发布' : '未发布'} />
      }, {
        title: '操作',
        dataIndex: 'operation',
        key: 'operation',
        customRender: (val) => {
          return (
            <div>
              <a-dropdown trigger={['click']} overlay={(
                <a-menu>
                  <a-menu-item onClick={() => this.editArticle(val.record)}>
                    <span>编辑</span>
                  </a-menu-item>
                  <a-menu-item onClick={() => this.editArticle(val.record, 'publish')}>
                    <span v-html={val.record.isPublished ? '取消发布' : '发布'}></span>
                  </a-menu-item>
                  <a-menu-item onClick={() => this.copyLink(val.record)}>
                    <span>复制链接</span>
                  </a-menu-item>
                  <a-menu-item onClick={() => this.editArticle(val.record, 'del')}>
                    <span>删除</span>
                  </a-menu-item>
                </a-menu>
              )}>
                <a-button>操作</a-button>
              </a-dropdown>
            </div>
          )
        }
      }]
    }
  },
  created() {
    this.getArticles()
  },
  methods: {
    handleOk(formState) {
      editArticle({variables: formState}).then(res => {
        console.log(res)
        this.handleCancel()
        this.getArticles()
      }).catch(err => {
        console.log(err)
      })
    },
    editArticle(record, type) {
      if (type == 'del') {
        this.handleOk({id: record.id, isDeleted: true})
      } else if (type == 'publish') {
        this.handleOk({id: record.id, isPublished: !record.isPublished})
      } else {
        this.$router.push(`/edit-article?id=${record.id}`)
      }
    },
    handleCancel() {
      this.showForm = false
      this.formState = {}
    },
    toAdd() {
      this.$router.push(`/edit-article`)
    },
    copyLink(record) {
      let value = `/pages/article/article.html?id=${record.id}`
      if (navigator.clipboard) {
        navigator.clipboard.writeText(value);
        this.$message.success('已复制到剪切板')
      }
    },
    getArticles() {
      getArticles().then(res => {
        if (res.errors) {
          this.$message.error(res.errors[0].message)
          return
        }
        let dataSource = res.data.articles
        console.log(dataSource)
        this.dataSource = dataSource
      })
    }
  },
}
</script>
<style lang="scss" scoped>
  
</style>