<template>
  <div>
    <div class="operations">
      <div style="flex:1"></div>
      <a-button @click="showForm = true">添加</a-button>
    </div>
    <giga-table :gData="dataSource" :gColumns="columns" />
    <BannerForm :visible="showForm" :formData="formState" @formCancel="handleCancel" @formOk="handleOk" /> 
  </div>
</template>
<script>
import { getBannerAd, editBannerAd } from '../http/api'
import GigaTable from '../components/GigaTable.vue'
import BannerForm from '../components/form/BannerForm'

export default {
  components: {
    GigaTable,
    BannerForm
  },
  data() {
    return {
      showForm: false,
      addItem: false,
      formState: {},
      dataSource: [],
      vehicles: [],
      products: [],
      gigaShop: [],
      columns: [{
        title: 'id',
        dataIndex: 'id',
        key: 'id',
        width: 80
      }, {
        title: '标题',
        dataIndex: 'title',
        key: 'title'
      }, {
        title: '介绍',
        dataIndex: 'subTitle',
        key: 'subTitle'
      }, {
        title: '跳转地址',
        dataIndex: 'gotoUrl',
        key: 'gotoUrl'
      }, {
        title: '图片',
        dataIndex: 'media',
        key: 'media',
        customRender: val => {
          return <img src={val.value && val.value.url || ''} style="width: 40px"  />
        }
      }, {
        title: '排序',
        dataIndex: 'displayOrder',
        key: 'displayOrder'
      }, {
        title: '是否发布',
        dataIndex: 'isPublished',
        key: 'isPublished',
        customRender: val => <span v-html={val.value ? '已发布' : '未发布'} />
      }, {
        title: '操作',
        dataIndex: 'operation',
        key: 'operation',
        customRender: (val) => {
          return (
            <div>
              <a-dropdown trigger={['click']} overlay={(
                <a-menu>
                  <a-menu-item onClick={() => this.editBannerAd(val.record)}>
                    <span>编辑</span>
                  </a-menu-item>
                  <a-menu-item onClick={() => this.editBannerAd(val.record, 'publish')}>
                    <span v-html={val.record.isPublished ? '取消发布' : '发布'}></span>
                  </a-menu-item>
                  <a-menu-item onClick={() => this.editBannerAd(val.record, 'del')}>
                    <span>删除</span>
                  </a-menu-item>
                </a-menu>
              )}>
                <a-button>操作</a-button>
              </a-dropdown>
            </div>
          )
        }
      }]
    }
  },
  created() {
    this.getBannerAd()
  },
  methods: {
    setImg(e, type) {
      let img = e[0]
      let formState = this.formState
      formState[type] = img.url
      this.formState = JSON.parse(JSON.stringify(formState))
    },
    handleOk(formState) {
      editBannerAd({variables: formState}).then(res => {
        console.log(res)
        this.handleCancel()
        this.getBannerAd()
      }).catch(err => {
        console.log(err)
      })
    },

    editBannerAd(record, type) {
      if (type == 'del') {
        this.handleOk({id: record.id, isDeleted: true})
      } else if (type == 'publish') {
        this.handleOk({id: record.id, isPublished: !record.isPublished})
      } else {
        record.mediaUrl = record.media.url
        this.formState = record
        this.showForm = true
      }
      
    },

    handleCancel() {
      this.showForm = false
      this.addItem = false
      this.formState = {}
    },
    getBannerAd() {
      getBannerAd().then(res => {
        if (res.errors) {
          this.$message.error(res.errors[0].message)
          return
        }
        let dataSource = res.data.bannerAd
        this.dataSource = dataSource
      })
    },
  },
}
</script>
<style lang="scss" scoped>
  
</style>