<template>
  <a-modal
    :visible="visible"
    title="车间产品"
    destroyOnClose
    :maskClosable="false"
    @ok="handleOk"
    @cancel="handleCancel"
    style="width: 60%;"
  >
    <a-form
      ref="form"
      :model="formState"
      name="form"
      :validateOnRuleChange="false"
      :label-col="{ span: 5 }"
      :wrapper-col="{ span: 19 }"
      autocomplete="off"
      scrollToFirstError
    >
      <a-form-item
        label="产品名称"
        name="name"
        :rules="[{ required: true, message: '输入产品名称' }]"
      >
        <a-input v-model:value="formState.name" name="name" />
      </a-form-item>
      <a-form-item
        label="价格"
        name="price"
        :rules="[{ required: true, message: '输入价格' }]"
      >
        <a-input v-model:value="formState.price" name="price" />
      </a-form-item>
      <a-form-item
        label="有赞Id"
        name="youzanIds"
      >
        <a-select
          ref="select"
          mode="multiple"
          v-model:value="formState.youzanIds"
          style="width: 100%"
          :options="youzanList"
          :field-names="{ label: 'label', value: 'id'}"
          optionFilterProp="name"
          @search="getYouzanList"
        />
        <!-- <a-input v-model:value="formState.youzanId" name="youzanId" /> -->
      </a-form-item>
      <a-form-item label="产品描述" name="description">
        <Editor ref="editorOne" v-model:value="formState.description"></Editor>
        <!-- <a-textarea v-model:value="formState.description" name="description" /> -->
      </a-form-item>
      <a-form-item label="标签" name="subTitle">
        <a-select
          v-model:value="formState.subTitle"
          mode="tags"
          style="width: 100%"
          :token-separators="[',']"
        />
      </a-form-item>
      <a-form-item label="适用车型" name="vehicleIds">
        <a-select
          ref="select"
          mode="multiple"
          v-model:value="formState.vehicleIds"
          style="width: 100%"
          :options="vehicles"
          :field-names="{ label: 'name', value: 'id'}"
          optionFilterProp="name"
        />
      </a-form-item>
      <!-- <a-form-item label="所属改色膜" name="vehicleFilmId">
        <a-select
          ref="select"
          v-model:value="formState.vehicleFilmId"
          showSearch
          :options="vehicleFilms"
          :field-names="{ label: 'name', value: 'id'}"
          optionFilterProp="name"
          style="width: 100%"
        />
      </a-form-item> -->
      <a-form-item label="所属品牌" name="brandId">
        <a-select
          ref="select"
          v-model:value="formState.brandId"
          style="width: 100%"
          showSearch
          :options="licensedBrands"
          :field-names="{ label: 'name', value: 'id'}"
          optionFilterProp="name"
        />
      </a-form-item>
      <a-form-item label="所属目录" name="gigaServiceId">
        <a-select
          ref="select"
          v-model:value="formState.gigaServiceId"
          showSearch
          :options="gigaServices"
          :field-names="{ label: 'name', value: 'id'}"
          optionFilterProp="name"
          style="width: 100%"
        />
      </a-form-item>
      <a-form-item label="分类" name="category">
        <a-select
          ref="select"
          v-model:value="formState.category"
          style="width: 100%"
        >
          <a-select-option value="车内">车内</a-select-option>
          <a-select-option value="车外">车外</a-select-option>
        </a-select>
      </a-form-item>
      <a-form-item label="封面图" name="coverImage">
        <GigaUpload
          v-model:value="formState.coverImage"
          name="coverImage"
          :imageUrl="(formState.coverImage && formState.coverImage.url) || ''"
          @setImg="setImg($event, 'coverImage')"
        />
      </a-form-item>
      <a-form-item label="视频介绍" name="video">
        <GigaUpload
          v-model:value="formState.video"
          name="video"
          :videoUrl="(formState.video && formState.video.url) || ''"
          @setImg="setImg($event, 'video')"
        />
      </a-form-item>
      <a-form-item label="图片列表" name="images">
        <GigaUpload
          v-model:value="formState.images"
          name="images"
          :images="formState.images || []"
          :multiple="true"
          @sortImg="formState.images = $event"
          @setImg="setImg($event, 'images', 'multiple')"
          @removeImg="removeImg($event, 'images')"
        />
      </a-form-item>
    </a-form>
  </a-modal>
</template>
<script>
import { ref, watch } from "vue";
import GigaUpload from "../GigaUpload.vue";
import Editor from "../Editor.vue";
import { getYouzanProducts } from "@/http/api";
export default {
  name: "ProductForm",
  components: {
    GigaUpload,
    Editor
  },
  props: [
    "visible",
    "formData",
    "vehicles",
    "gigaServices",
    "licensedBrands",
    "vehicleFilms",
  ],
  setup(props) {
    const formState = ref(props.formData);
    const youzanList = ref([]);
    const youzanKeyword = ref('')
    const getYouzanList = (keyword) => {
      getYouzanProducts({variables: {keyword}}).then(res => {
        console.log(res)
        if (res.errors) {
          this.$message.error(res.errors[0].message)
        } else {
          let list = res.data.youzanProducts || []
          list.forEach(item => {
            item.label = `${item.name} - ${item.skuName}`
          })
          youzanList.value = list
        }
      })
    }
    watch(() => props.formData, (newVal) => {
      formState.value = newVal
      getYouzanList(newVal.name)
    });
    return {
      youzanKeyword,
      youzanList,
      formState,
      form: ref(null),
      editorOne: ref(null),
      getYouzanList
    };
  },

  methods: {
    setImg(e, param, multiple) {
      console.log(e);
      let img = e[0];
      let formState = this.formState;
      if (multiple) {
        if (!formState[param] || !Array.isArray(formState[param]))
          formState[param] = [];
        formState[param] = formState[param].concat(img);
      } else {
        formState[param] = img;
      }
      console.log(formState);
      this.formState = JSON.parse(JSON.stringify(formState));
    },
    removeImg(id, param) {
      console.log(id);
      let formState = this.formState;
      let images = formState[param];
      images.forEach((e, index) => {
        if (e.id == id) {
          images.splice(index, 1);
        }
      });
      formState[param] = images;
      this.formState = JSON.parse(JSON.stringify(formState));
    },
    handleOk() {
      this.form
        .validateFields()
        .then((res) => {
          let formState = Object.assign(this.formState, res);
          formState.coverImageId = (formState.coverImage && formState.coverImage.id) || null;
          formState.videoId = (formState.video && formState.video.id) || null;
          formState.imageIds = formState.images ? formState.images.map((e) => e.id) : [];
          formState.price = formState.price.replace(/¥/g, '').replace(/,/g, '')
          formState.description = this.editorOne.valueHtml
          this.$emit("formOk", formState);
        })
        .catch((err) => {
          console.error(err);
          this.$message.error(err.errorFields[0].errors[0]);
        });
    },
    handleCancel() {
      this.$emit("formCancel");
    },
  },
};
</script>