<template>
  <div style="border: 1px solid #ccc">
    <Toolbar
      style="border-bottom: 1px solid #ccc"
      :editor="editorRef"
      :defaultConfig="toolbarConfig"
      :mode="mode"
    />
    <Editor
      style="overflow-y: hidden;height: 600px"
      v-model="valueHtml"
      :defaultConfig="editorConfig"
      :mode="mode"
      @onCreated="handleCreated"
      @customPaste="customPaste"
    />
  </div>
</template>

<script>
 /* eslint-disable */ 
import '@wangeditor/editor/dist/css/style.css'
import { onBeforeUnmount, ref, shallowRef, watch } from 'vue'
import { Editor, Toolbar } from '@wangeditor/editor-for-vue'
import { upLoadImage } from '../libs/upload'
function getImageFileFromUrl(url, fileName, callback) {
  console.log(2)
  var blob = null
  var xhr = new XMLHttpRequest() 
  console.log(url)
  xhr.open("GET", url)
  xhr.setRequestHeader('Accept', 'image/*')
  xhr.responseType = "blob"
  xhr.onload = () => {
  if (xhr.status == 200) {
    blob = xhr.response
    let imgFile = new File([blob], fileName, {type: 'image/jpeg'})
    callback.call(this,imgFile)
  }}
  xhr.send()
}
export function convertBase64UrlToBlob(urlData) {
  let arr = urlData.split(',')
  let mime = arr[0].match(/:(.*?);/)[1]
  let bstr = atob(arr[1])
  let n = bstr.length
  let u8arr = new Uint8Array(n)
  while (n--) {
    u8arr[n] = bstr.charCodeAt(n)
  }
  return new Blob([u8arr], {type: mime})
}
export default {
  components: { Editor, Toolbar },
  props: ['value'],
  setup(props) {
    const editorRef = shallowRef()
    const valueHtml = ref('')
    const toolbarConfig = {}
    const editorConfig = { placeholder: '请输入内容...', MENU_CONF: {} }
    watch(() => props.value, (newVal) => {
      try {
        editorRef.value.setHtml(newVal)
      } catch (error) {
        setTimeout(() => {
          editorRef.value.setHtml(newVal)
        }, 10)
      }
    }, {immediate: true})
    const upLoad = (file, insertFn) => {
      console.log(file)
      if (file) {
        let fileData = {}
        fileData.name = file.name
        fileData.type = file.type
        fileData.originFileObj = file
        upLoadImage(fileData).then(res => {
          console.log(res)
          let img = res.data.uploadFiles
          console.log(img)
          // emit('setImg', img)
          insertFn(img[0].url);
        })
      }
    }
    const imgUrlSwitchBlob = (param) => {
      return new Promise((resolve) => {
        console.log(1, param)
        getImageFileFromUrl(param, 'file', (res) => {
          console.log(res)
          let reads = new FileReader();
          reads.readAsDataURL(res)
          reads.onload = async function () {
            let file = convertBase64UrlToBlob(this.result)
            let fileData = {}
            fileData.name = file.name || 'file'
            fileData.type = file.type
            fileData.originFileObj = file
            upLoadImage(fileData).then(res => {
              console.log(res)
              let img = res.data.uploadFiles
              console.log(img)
              resolve(img[0])
            })
          }
        })
        // let suffix = param.substring(param.lastIndexOf(".") + 1)
        // let image = new Image()
        // image.setAttribute("crossOrigin", "anonymous")
        // image.src = param
        // console.log(0)
        // image.onload = () => {
        //   console.log(1)
        //   let canvas = document.createElement("canvas")
        //   canvas.width = image.width
        //   canvas.height = image.height
        //   let ctx = canvas.getContext("2d")
        //   ctx.drawImage(image, 0, 0, image.width, image.height)
        //   console.log(image)
        //   canvas.toBlob((blob) => {
        //     console.log()
            // upLoad({originFileObj: blob, name: 'file', type: suffix}).then((res) => {
            //   resolve(res)
            // });
        //   });
        // };
      });
    }
    const disposePasteImg = (pasteStr) => {
      return new Promise((resolve) => {
        let imgNum = 0
        let imgReg = /<img.*?(?:>|\/>)/gi
        let srcReg = /src=[\'\"]?([^\'\"]*)[\'\"]?/i
        pasteStr.replace(imgReg, (txt) => {
          return txt.replace(srcReg, (src) => {
            let img_src = src.match(srcReg)[1]
            imgNum++
            imgUrlSwitchBlob(img_src).then((res) => {
              pasteStr = pasteStr.replace(img_src, res.url)
              imgNum--
              if (imgNum == 0) {
                resolve(pasteStr)
              }
            })
          })
        })
      })
    }
    editorConfig.height = 300
    editorConfig.MENU_CONF['uploadImage'] = {
      customUpload(file, insertFn) {
        console.log(file)
        upLoad(file, insertFn)
      },
    }
    const customPaste = () => {
      // editor.insertText('xxx')
      setTimeout(() => {
        console.log(valueHtml.value)
        disposePasteImg(valueHtml.value).then(res => {
          console.log(res)
          valueHtml.value = res
        })
      }, 100)
    }
    onBeforeUnmount(() => {
      const editor = editorRef.value
      if (editor == null) return
      editor.destroy()
    })
    const handleCreated = (editor) => {
      editorRef.value = editor
    }

    return {
      editorRef,
      valueHtml,
      mode: 'default', // 或 'simple'
      toolbarConfig,
      editorConfig,
      handleCreated,
      customPaste
    };
  }
};
</script>
<style>

</style>
