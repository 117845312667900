<template>
  <a-modal :visible="visible" title="添加改色膜颜色" destroyOnClose :maskClosable="false"  @ok="handleOk()" @cancel="handleCancel">
      <a-form
        ref="form"
        :model="formState"
        name="form"
        :validateOnRuleChange="false"
        :label-col="{ span: 5 }"
        :wrapper-col="{ span: 19 }"
        autocomplete="off"
        scrollToFirstError
      >
        <a-form-item
          label="名称"
          name="name"
          :rules="[{ required: true, message: '输入名称' }]"
        >
          <a-input v-model:value="formState.name" name="name" />
        </a-form-item>
        <a-form-item
          label="尺寸"
          name="size"
          :rules="[{ required: true, message: '选择尺寸' }]"
        >
          <a-select
            ref="select"
            v-model:value="formState.size"
            :options="sizeCategory"
            :field-names="{ label: 'name', value: 'id'}"
            optionFilterProp="name"
            style="width: 100%"
          />
        </a-form-item>
        <a-form-item
          label="适合车型"
          name="carType"
          :rules="[{ required: true, message: '选择车型' }]"
        >
          <a-select
            v-model:value="formState.carType"
            style="width: 100%"
          >
            <a-select-option value="m3" label="Model 3">Model 3</a-select-option>
            <a-select-option value="my" label="Model Y">Model Y</a-select-option>
            <a-select-option value="ms" label="Model S">Model S</a-select-option>
            <a-select-option value="mx" label="Model X">Model X</a-select-option>
          </a-select>
        </a-form-item>
        <a-form-item
          label="色系"
          name="color"
          extra="#eg: 红、橙、黄、黑、白"
        >
          <a-input v-model:value="formState.color" name="color" />
        </a-form-item>
        <a-form-item
          label="缩略图"
          name="sampleId"
        >
          <GigaUpload v-model:value="formState.sampleId" name="sampleId" :imageUrl="formState.sampleUrl || (formState.sample && formState.sample.url)" @setImg="setImg($event, 'sample')"  />
        </a-form-item>
        <a-form-item
          label="排序"
          name="displayOrder"
        >
          <a-input v-model:value="formState.displayOrder" name="displayOrder" />
        </a-form-item>
      </a-form>
    </a-modal>
</template>
<script>
import { ref, watch } from 'vue';
import GigaUpload from '../GigaUpload.vue'
export default {
  name: 'VehicleFilmsForm',
  props: ['visible', 'formData'],
  components: {
    GigaUpload
  },
  setup(props) {
    const formState = ref(props.formData)
    const sizeCategory = ref([{name: '18寸', id: 18}, {name: '19寸', id: 19}, {name: '20寸', id: 20}, {name: '21寸', id: 21}, {name: '22寸', id: 22}])
    watch(() => props.formData, (newVal) => formState.value = newVal)
    return {
      sizeCategory,
      formState,
      form: ref(null),
    };
  },
  
  methods: {
    setImg(e, type) {
      let img = e[0]
      let formState = this.formState
      formState[`${type}Id`] = img.id
      formState[`${type}Url`] = img.url
      this.formState = JSON.parse(JSON.stringify(formState))
    },
    handleOk() {
      this.form.validateFields().then(res => {
        res.displayOrder = res.displayOrder ? parseInt(res.displayOrder) : 0
        let formState = Object.assign(this.formState, res)
        this.$emit('formOk', formState)
      }).catch(err => {
        console.error(err)
        this.$message.error(err.errorFields[0].errors[0])
      })
    },
    handleCancel() {
      this.$emit('formCancel')
    },
  },

}
</script>