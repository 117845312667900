<template>
  <div>
    <div class="operations">
      <a-input-search
        v-model:value="keywordValue"
        placeholder="输入关键字搜索"
        enter-button
        @search="getProducts"
        style="width: 300px;outline: none"
      />
      <div style="flex: 1" class="switch">
        <span>是否显示改色产品</span><a-switch v-model:checked="checked" @change="getProducts" />
      </div>
      <a-button @click="showForm = true">添加</a-button>
    </div>
    <GigaTable :gData="dataSource" :gColumns="columns" />
    <ProductForm
      :visible="showForm"
      :formData="formState"
      :vehicleFilms="vehicleFilms"
      :vehicles="vehicles"
      :gigaServices="gigaServices"
      :licensedBrands="licensedBrands"
      @formCancel="handleCancel"
      @formOk="handleOk"
    />
    <CasebookForm :visible="showCasebookForm" :gigaShop="gigaShop" :gigaServiceManagers="gigaServiceManagers" :vehicles="vehicles" :products="products" :formData="casebookFormformState" @formCancel="handleCancel" @formOk="casebookFormHandleOk" /> 
  </div>
</template>
<script>
import {
  getProducts,
  getGigaServices,
  getLicensedBrands,
  getVehicles,
  editProduct,
  getVehicleFilms,
  getChoice,
  editCasebook
} from "../http/api";
import GigaTable from "../components/GigaTable.vue";
import ProductForm from "../components/form/ProductForm";
import CasebookForm from "../components/form/CasebookForm";
export default {
  components: {
    GigaTable,
    ProductForm,
    CasebookForm
  },
  data() {
    return {
      checked: true,
      keywordValue: '',
      casebookFormformState: {},
      showCasebookForm: false,
      showForm: false,
      formState: {},
      dataSource: [],
      products: [],
      page: 1,
      vehicles: [],
      licensedBrands: [],
      gigaServices: [],
      gigaShop: [],
      gigaServiceManagers: [],
      columns: [
        {
          title: "id",
          dataIndex: "id",
          key: "id",
          width: 60,
        },
        {
          title: "产品名称",
          dataIndex: "name",
          key: "name",
        },
        {
          title: "产品价格",
          dataIndex: "price",
          key: "price",
        },
        {
          title: "产品介绍",
          dataIndex: "description",
          key: "description",
          customRender: (val) => {
            return (
              <a-tooltip title={val.value}>
                <div style={{ maxWidth: "100%" }}>
                  {val.value.substring(0, 5)}
                  {val.value.length > 5 ? "..." : ""}
                </div>
              </a-tooltip>
            );
          },
        },
        {
          title: "标签",
          dataIndex: "subTitle",
          key: "subTitle",
          customRender: (val) => {
            return (
              <div>
                {val.value
                  ? val.value.map((e) => <span v-html={e + "，"}></span>)
                  : ""}
              </div>
            );
          },
        },
        {
          title: "适用车型",
          dataIndex: "vehicles",
          key: "vehicles",
          customRender: (val) => {
            return (
              <a-tooltip title={val.value.map((e) => e.name).toString()}>
                <div style={{ maxWidth: "100%" }}>
                  {val.value
                    .map((e) => e.name)
                    .toString()
                    .substring(0, 8)}
                  {val.value.map((e) => e.name).toString().length > 8
                    ? "..."
                    : ""}
                </div>
              </a-tooltip>
            );
          },
        },
        {
          title: "封面图",
          dataIndex: "coverImage",
          key: "coverImage",
          customRender: (val) => {
            return (
              <img
                src={(val.value && val.value.url) || ""}
                style="width: 40px"
              />
            );
          },
        },
        {
          title: "所属目录",
          dataIndex: "gigaService",
          key: "gigaService",
          customRender: (val) => {
            return <span v-html={(val.value && val.value.name) || ""} />;
          },
        },
        {
          title: "所属品牌",
          dataIndex: "brand",
          key: "brand",
          customRender: (val) => {
            return <span v-html={(val.value && val.value.name) || ""} />;
          },
        },
        {
          title: "分类",
          dataIndex: "category",
          key: "category",
        },
        {
          title: "排序",
          dataIndex: "displayOrder",
          key: "displayOrder",
          width: 60,
        }, 
        {
          title: '是否发布',
          dataIndex: 'isPublished',
          key: 'isPublished',
          customRender: val => <span v-html={val.value ? '已发布' : '未发布'} />
        },
        {
          title: "操作",
          dataIndex: "operation",
          key: "operation",
          width: 200,
          customRender: (val) => {
            return (
              <div>
                <a-dropdown
                  trigger={["click"]}
                  overlay={
                    <a-menu>
                      <a-menu-item onClick={() => this.editProduct(val.record, 'copy')}>
                        <span>复制</span>
                      </a-menu-item>
                      <a-menu-item onClick={() => this.editProduct(val.record)}>
                        <span>编辑</span>
                      </a-menu-item>
                      <a-menu-item onClick={() => this.editProduct(val.record, "publish")}>
                        <span v-html={val.record.isPublished ? "取消发布" : "发布"}></span>
                      </a-menu-item>
                      <a-menu-item onClick={() => this.editProduct(val.record, "del")}>
                        <span>删除</span>
                      </a-menu-item>
                    </a-menu>
                  }
                >
                  <a-button>操作</a-button>
                </a-dropdown>
                <a-button style={{marginLeft: '10px'}} onClick={() => this.addCasebook(val.record)}>添加案例</a-button>
              </div>
            );
          },
        },
      ],
    };
  },
  created() {
    this.getProducts();
    this.getData();
  },
  methods: {
    setImg(e, type) {
      let img = e[0];
      let formState = this.formState;
      formState[type] = img.url;
      this.formState = JSON.parse(JSON.stringify(formState));
    },
    addCasebook(record) {
      console.log(record)
      this.casebookFormformState = {
        title: record.name,
        productId: record.id
      }
      this.showCasebookForm = true
      console.log(this.showCasebookForm)
    },
    casebookFormHandleOk(formState) {
      formState.isPublished = true
      editCasebook({variables: formState}).then(res => {
        console.log(res)
        this.handleCancel()
      }).catch(err => {
        console.log(err)
      })
    },
    handleOk(formState, type) {
      let data = JSON.parse(JSON.stringify(formState))
      if (!type) data.subTitle = data.subTitle ? data.subTitle.toString() : '';
      editProduct({ variables: data })
        .then((res) => {
          console.log(res);
          this.handleCancel();
          this.getProducts();
        })
        .catch((err) => {
          console.log(err);
        });
    },

    editProduct(record, type) {
      if (type == "del") {
        this.handleOk({ id: record.id, isDeleted: true }, 'del');
      } else if (type == "publish") {
        this.handleOk({ id: record.id, isPublished: !record.isPublished }, 'publish');
      } else if (type == 'copy') {
        console.log(record)
        let data = JSON.parse(JSON.stringify(record))
        data.id = null;
        data.isPublished = false;
        data.gigaServiceId = data.gigaService.id;
        data.vehicleIds = data.vehicles.map((e) => e.id);
        data.youzanIds = data.youzanProducts.map((e) => e.id);
        if (data.brand) data.brandId = data.brand.id;
        if (data.vehicleFilm) data.vehicleFilmId = data.vehicleFilm && data.vehicleFilm.id || ''
        this.formState = data;
        this.showForm = true;
      } else {
        record.gigaServiceId = record.gigaService.id;
        record.vehicleIds = record.vehicles.map((e) => e.id);
        record.youzanIds = record.youzanProducts.map((e) => e.id);
        if (record.brand) record.brandId = record.brand.id;
        if (record.vehicleFilm) record.vehicleFilmId = record.vehicleFilm && record.vehicleFilm.id || ''
        this.formState = record;
        this.showForm = true;
      }
    },
    handleCancel() {
      this.showForm = false;
      this.showCasebookForm = false;
      this.formState = {};
      this.casebookFormformState = {}
    },
    getProducts() {
      let data = {variables: {limit: 50, inCatalogue: false, keyword: this.keywordValue || null}}
      if (!this.checked) {
        data.variables.inCatalogue = true;
        data.variables.limit = 200;
      }
      getProducts(data).then((res) => {
        if (res.errors) {
          this.$message.error(res.errors[0].message);
          return;
        }
        let dataSource = res.data.products;
        this.dataSource = dataSource;
      });
    },
    getData() {
      Promise.all([
        getGigaServices(),
        getLicensedBrands(),
        getVehicles(),
        getVehicleFilms(),
        getChoice(),
        getProducts({variables: {limit: 99}})
      ]).then((res) => {
        console.log(res);
        let gigaServices = res[0].data.gigaServices;
        let licensedBrands = res[1].data.licensedBrands;
        let vehicles = res[2].data.vehicles;
        let vehicleFilms = res[3].data.vehicleFilms;
        let gigaShop = res[4].data.choice.gigaShop
        let gigaServiceManagers = res[4].data.choice.gigaServiceManagers
        let products = res[5].data.products;
        this.gigaServices = gigaServices;
        this.licensedBrands = licensedBrands;
        this.vehicles = vehicles;
        this.vehicleFilms = vehicleFilms;
        this.gigaShop= gigaShop
        this.products= products
        this.gigaServiceManagers = gigaServiceManagers
      });
    },
  },
};
</script>
<style lang="scss" scoped>
  .switch {
    margin-left: 24px;
    display: flex;
    align-items: center;
    span {
      margin-right: 12px;
      font-size: 12px;
      font-weight: 600;
    }
  }
</style>