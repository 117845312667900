import { post, get, post_xt } from './axios'
const { gigaCataloguesQuery, editVehicleBrandQuery, vehicleBrandsQuery, editVehicleQuery, editGigaServiceQuery, configureCatalogueQuery,
  vehicleFilmCategoryQuery, editFilmCategoryQuery, productsQuery, outlookDiyQuery, editFilmQuery,
  licensedBrandsQuery, editLicenseBrandQuery, gigaServicesQuery, vehiclesQuery, editProductQuery,
  casebooksQuery, casebooksTableQuery, editCasebookQuery, choiceQuery, editOutlookDiyQuery, vehicleFilmsQuery, articlesQuery,
  editArticleQuery, articleQuery, bannerAdQuery, editBannerAdQuery, editShopQuery, shopsQuery, 
  generateQrCodeQuery, qrCodeStatusQuery, wordSkillsQuery, editWordSkillQuery, wordSkillQuery, youzanProductsQuery,
  editPriceTableQuery, priceTableQuery, priceTablesQuery, specialUsersQuery, gigaManagerCasebooksQuery,
  editWheelQuery, editOutlookDiyWheelQuery, outlookDiyWheelQuery, loginByFeishuQuery, 
  couponActivities, filterUserCount, addOfflineCouponActivity, refreshYouzanCouponActivity, youzanCouponActivities,
  updateOfflineCouponActivity, couponSenders, addUserMatchRule, createUserCoupons, updateCouponSender, matchRules, updateUserMatchRule, configureReferralExpertQuery,
  referralActivityExpertsQuery, updateReferralExpertQuery} = require('./query')

export const updateReferralExpert = (data = {}) => post('/api/graphql/', Object.assign(data, updateReferralExpertQuery()))
export const configureReferralExpert = (data = {}) => post('/api/graphql/', Object.assign(data, configureReferralExpertQuery()))
export const getReferralActivityExperts = (data = {}) => post('/api/graphql/', Object.assign(data, referralActivityExpertsQuery()))

export const editOutlookDiyWheel = (data = {}) => post('/api/graphql/', Object.assign(data, editOutlookDiyWheelQuery()))
export const editWheel = (data = {}) => post('/api/graphql/', Object.assign(data, editWheelQuery()))
export const getOutlookDiyWheel = (data = {}) => post('/api/graphql/', Object.assign(data, outlookDiyWheelQuery()))
export const loginByFeishu = (data = {}) => post('/api/graphql/', Object.assign(data, loginByFeishuQuery()))


export const downloadGigaManagerCasebooks = (data = {}) => get('/api/v1/download_giga_manager_casebooks', data)
export const getGigaManagerCasebooks = (data = {}) => post('/api/graphql/', Object.assign(data, gigaManagerCasebooksQuery()))
export const getYouzanProducts = (data = {}) => post('/api/graphql/', Object.assign(data, youzanProductsQuery()))
export const getSpecialUsers = (data = {}) => post('/api/graphql/', Object.assign(data, specialUsersQuery()))
export const editPriceTable = (data = {}) => post('/api/graphql/', Object.assign(data, editPriceTableQuery()))
export const getPriceTable = (data = {}) => post('/api/graphql/', Object.assign(data, priceTableQuery()))
export const getPriceTables = (data = {}) => post('/api/graphql/', Object.assign(data, priceTablesQuery()))
export const getWordSkill = (data = {}) => post('/api/graphql/', Object.assign(data, wordSkillQuery()))
export const editWordSkill = (data = {}) => post('/api/graphql/', Object.assign(data, editWordSkillQuery()))
export const getWordSkills = (data = {}) => post('/api/graphql/', Object.assign(data, wordSkillsQuery()))
export const getQrCodeStatus = (data = {}) => post('/api/graphql/', Object.assign(data, qrCodeStatusQuery()))
export const getGenerateQrCode = (data = {}) => post('/api/graphql/', Object.assign(data, generateQrCodeQuery()))
export const getGigaCatalogues = (data = {}) => post('/api/graphql/', Object.assign(data, gigaCataloguesQuery()))
export const editVehicleBrand = (data = {}) => post('/api/graphql/', Object.assign(data, editVehicleBrandQuery()))
export const getVehicleBrands = (data = {}) => post('/api/graphql/', Object.assign(data, vehicleBrandsQuery()))
export const editVehicle = (data = {}) => post('/api/graphql/', Object.assign(data, editVehicleQuery()))
export const editGigaService = (data = {}) => post('/api/graphql/', Object.assign(data, editGigaServiceQuery()))
export const configureCatalogue = (data = {}) => post('/api/graphql/', Object.assign(data, configureCatalogueQuery()))
export const getVehicleFilmCategory = (data = {}) => post('/api/graphql/', Object.assign(data, vehicleFilmCategoryQuery()))
export const editFilmCategory = (data = {}) => post('/api/graphql/', Object.assign(data, editFilmCategoryQuery()))
export const getProducts = (data = {}) => post('/api/graphql/', Object.assign(data, productsQuery()))
export const getOutlookDiy = (data = {}) => post('/api/graphql/', Object.assign(data, outlookDiyQuery()))
export const getVehicleFilms = (data = {}) => post('/api/graphql/', Object.assign(data, vehicleFilmsQuery()))
export const editFilm = (data = {}) => post('/api/graphql/', Object.assign(data, editFilmQuery()))
export const getLicensedBrands = (data = {}) => post('/api/graphql/', Object.assign(data, licensedBrandsQuery()))
export const editLicenseBrand = (data = {}) => post('/api/graphql/', Object.assign(data, editLicenseBrandQuery()))
export const getGigaServices = (data = {}) => post('/api/graphql/', Object.assign(data, gigaServicesQuery()))
export const getVehicles = (data = {}) => post('/api/graphql/', Object.assign(data, vehiclesQuery()))
export const editProduct = (data = {}) => post('/api/graphql/', Object.assign(data, editProductQuery()))
export const getCasebooks = (data = {}) => post('/api/graphql/', Object.assign(data, casebooksQuery()))
export const getCasebooksTable = (data = {}) => post('/api/graphql/', Object.assign(data, casebooksTableQuery()))
export const editCasebook = (data = {}) => post('/api/graphql/', Object.assign(data, editCasebookQuery()))
export const getChoice = (data = {}) => post('/api/graphql/', Object.assign(data, choiceQuery()))
export const editOutlookDiy = (data = {}) => post('/api/graphql/', Object.assign(data, editOutlookDiyQuery()))
export const getArticles = (data = {}) => post('/api/graphql/', Object.assign(data, articlesQuery()))
export const editArticle = (data = {}) => post('/api/graphql/', Object.assign(data, editArticleQuery()))
export const getArticle = (data = {}) => post('/api/graphql/', Object.assign(data, articleQuery()))
export const getBannerAd = (data = {}) => post('/api/graphql/', Object.assign(data, bannerAdQuery()))
export const editBannerAd = (data = {}) => post('/api/graphql/', Object.assign(data, editBannerAdQuery()))
export const editShop = (data = {}) => post('/api/graphql/', Object.assign(data, editShopQuery()))
export const getShops = (data = {}) => post('/api/graphql/', Object.assign(data, shopsQuery()))

export const uploadFiles = (data = {}) => post('/api/graphql/', data)


// couponInfo
export const getCouponActivities = (data = {}) => post_xt('/api/graphql/', Object.assign(data, couponActivities()))
export const userCount = (data = {}) => post_xt('/api/graphql/', Object.assign(data, filterUserCount()))
export const addCouponActivity = (data = {}) => post_xt('/api/graphql/', Object.assign(data, addOfflineCouponActivity()))
export const updateCouponActivity = (data = {}) => post_xt('/api/graphql/', Object.assign(data, updateOfflineCouponActivity()))
export const getCouponSenders = (data = {}) => post_xt('/api/graphql/', Object.assign(data, couponSenders()))
export const addMatchRule = (data = {}) => post_xt('/api/graphql/', Object.assign(data, addUserMatchRule()))
export const sendUserCoupons = (data = {}) => post_xt('/api/graphql/', Object.assign(data, createUserCoupons()))
export const updateUserCouponSender = (data = {}) => post_xt('/api/graphql/', Object.assign(data, updateCouponSender()))
export const getMatchRules = (data = {}) => post_xt('/api/graphql/', Object.assign(data, matchRules()))
export const updateMatchRule = (data = {}) => post_xt('/api/graphql/', Object.assign(data, updateUserMatchRule()))
export const refreshYouzanCoupon = (data = {}) => post_xt('/api/graphql/', Object.assign(data, refreshYouzanCouponActivity()))
export const getYouzanCouponActivities = (data = {}) => post_xt('/api/graphql/', Object.assign(data, youzanCouponActivities()))