<template>
  <div>
    <div class="operations">
      <div style="flex:1"></div>
      <a-button @click="showForm = true">添加</a-button>
    </div>
    <giga-table :gData="dataSource" :gColumns="columns" />
    <ShopForm :visible="showForm" :formData="formState" @formCancel="handleCancel" @formOk="handleOk" /> 
  </div>
</template>
<script>
import { getShops, editShop } from '../http/api'
import GigaTable from '../components/GigaTable.vue'
import ShopForm from '../components/form/ShopForm.vue'

export default {
  components: {
    GigaTable,
    ShopForm
  },
  data() {
    return {
      showForm: false,
      addItem: false,
      formState: {},
      dataSource: [],
      vehicles: [],
      products: [],
      gigaShop: [],
      columns: [{
        title: 'id',
        dataIndex: 'id',
        key: 'id',
        width: 80
      }, {
        title: '名称',
        dataIndex: 'name',
        key: 'name'
      }, {
        title: '地址',
        dataIndex: 'address',
        key: 'address'
      }, {
        title: '标题',
        dataIndex: 'title',
        key: 'title'
      }, {
        title: '描述',
        dataIndex: 'description',
        key: 'description'
      }, {
        title: '手机号',
        dataIndex: 'phoneNum',
        key: 'phoneNum'
      }, {
        title: '封面图',
        dataIndex: 'coverImage',
        key: 'coverImage',
        customRender: val => {
          return <img src={val.value && val.value.url || ''} style="width: 40px"  />
        }
      }, {
        title: '是否发布',
        dataIndex: 'isPublished',
        key: 'isPublished',
        customRender: val => <span v-html={val.value ? '已发布' : '未发布'} />
      }, {
        title: '门店类型',
        dataIndex: 'isPartnerShop',
        key: 'isPartnerShop',
        customRender: val => <span v-html={val.value ? '精选合作店' : '自营店'} />
      }, {
        title: '操作',
        dataIndex: 'operation',
        key: 'operation',
        customRender: (val) => {
          return (
            <div>
              <a-dropdown trigger={['click']} overlay={(
                <a-menu>
                  <a-menu-item onClick={() => this.editShop(val.record)}>
                    <span>编辑</span>
                  </a-menu-item>
                  <a-menu-item onClick={() => this.editShop(val.record, 'publish')}>
                    <span v-html={val.record.isPublished ? '取消发布' : '发布'}></span>
                  </a-menu-item>
                  <a-menu-item onClick={() => this.editShop(val.record, 'del')}>
                    <span>删除</span>
                  </a-menu-item>
                </a-menu>
              )}>
                <a-button>操作</a-button>
              </a-dropdown>
            </div>
          )
        }
      }]
    }
  },
  created() {
    this.getShops()
  },
  methods: {
    setImg(e, type) {
      let img = e[0]
      let formState = this.formState
      formState[type] = img.url
      this.formState = JSON.parse(JSON.stringify(formState))
    },
    handleOk(formState) {
      editShop({variables: formState}).then(res => {
        console.log(res)
        this.handleCancel()
        this.getShops()
      }).catch(err => {
        console.log(err)
      })
    },

    editShop(record, type) {
      if (type == 'del') {
        this.handleOk({id: record.id, isDeleted: true})
      } else if (type == 'publish') {
        this.handleOk({id: record.id, isPublished: !record.isPublished})
      } else {
        record.coverImageUrl = record.coverImage && record.coverImage.url
        if (record.location && record.location.latitude) record.location = `${record.location.latitude},${record.location.longitude}`
        this.formState = record
        this.showForm = true
      }
      
    },

    handleCancel() {
      this.showForm = false
      this.addItem = false
      this.formState = {}
    },
    getShops() {
      getShops().then(res => {
        if (res.errors) {
          this.$message.error(res.errors[0].message)
          return
        }
        let dataSource = res.data.shops
        this.dataSource = dataSource
      })
    },
  },
}
</script>
<style lang="scss" scoped>
  
</style>