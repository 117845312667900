<template>
  <a-modal :visible="visible" title="添加品牌" destroyOnClose :maskClosable="false"  @ok="handleOk('vehicleBrandForm')" @cancel="handleCancel">
    <a-form
      ref="form"
      :model="formState"
      :validateOnRuleChange="false"
      name="form"
      :label-col="{ span: 5 }"
      :wrapper-col="{ span: 19 }"
      autocomplete="off"
      scrollToFirstError
    >
      <a-form-item
        label="名称"
        name="name"
        :rules="[{ required: true, message: '输入品牌名称' }]"
      >
        <a-input v-model:value="formState.name" name="name" />
      </a-form-item>

      <a-form-item
        label="Logo"
        name="logoId"
        :rules="[{ required: true, message: 'Please input your password!' }]"
      >
        <GigaUpload v-model:value="formState.logoId" :imageUrl="formState.logo"  name="logoId" @setImg="setImg($event, 'logo')" />
      </a-form-item>
        <a-form-item
        label="排序"
        name="displayOrder"
      >
        <a-input v-model:value="formState.displayOrder" name="displayOrder" />
      </a-form-item>
    </a-form>
  </a-modal>
</template>
<script>
import { ref, watch } from 'vue';
import GigaUpload from '../GigaUpload.vue'
export default {
  name: 'vehicleBrandForm',
  components: {
    GigaUpload
  },
  props: ['visible', 'formData'],
  setup(props) {
    const formState = ref(props.formData)
    watch(() => props.formData, (newVal) => formState.value = newVal)
    return {
      formState,
      form: ref(null),
    };
  },
  
  methods: {
    setImg(e, type) {
      let img = e[0]
      let formState = this.formState
      formState[`${type}Id`] = img.id
      formState[type] = img.url
      this.formState = JSON.parse(JSON.stringify(formState))
    },
    handleOk() {
      this.form.validateFields().then(res => {
        res.displayOrder = res.displayOrder ? parseInt(res.displayOrder) : 0
        let formState = Object.assign(this.formState, res)
        this.$emit('formOk', formState)
      }).catch(err => {
        console.error(err)
        this.$message.error(err.errorFields[0].errors[0])
      })
    },
    handleCancel() {
      this.$emit('formCancel')
    },
  },

}
</script>