import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import {
  Button,
  message,
  Layout,
  Alert,
  Menu,
  Breadcrumb,
  Table,
  Modal,
  Form,
  Input,
  InputNumber,
  Upload,
  Tooltip,
  Select,
  Dropdown,
  Switch,
  DatePicker,
  Drawer,
  Image,
  Badge,
} from "ant-design-vue";
import "ant-design-vue/dist/antd.less";
import {
  MessageOutlined,
  PlusOutlined,
  LoadingOutlined,
  HomeOutlined,
  BarsOutlined,
  CarOutlined,
  ProjectOutlined,
  TrademarkOutlined,
  InboxOutlined,
  ShoppingOutlined,
  BookOutlined,
  FileImageOutlined,
  ShopOutlined,
  UserOutlined,
  UpCircleOutlined,
  DownCircleOutlined
} from "@ant-design/icons-vue";
const app = createApp(App);
app
  .use(Button)
  .use(Layout)
  .use(Image)
  .use(Drawer)
  .use(Alert)
  .use(Menu)
  .use(DatePicker)
  .use(Modal)
  .use(Breadcrumb)
  .use(router)
  .use(Table)
  .use(Form)
  .use(Input)
  .use(InputNumber)
  .use(Upload)
  .use(Tooltip)
  .use(Select)
  .use(Switch)
  .use(Dropdown)
  .use(Badge)
  .component("MessageOutlined", MessageOutlined)
  .component("UserOutlined", UserOutlined)
  .component("ShopOutlined", ShopOutlined)
  .component("FileImageOutlined", FileImageOutlined)
  .component("PlusOutlined", PlusOutlined)
  .component("LoadingOutlined", LoadingOutlined)
  .component("HomeOutlined", HomeOutlined)
  .component("BarsOutlined", BarsOutlined)
  .component("CarOutlined", CarOutlined)
  .component("ProjectOutlined", ProjectOutlined)
  .component("TrademarkOutlined", TrademarkOutlined)
  .component("InboxOutlined", InboxOutlined)
  .component("ShoppingOutlined", ShoppingOutlined)
  .component("BookOutlined", BookOutlined)
  .component("UpCircleOutlined", UpCircleOutlined)
  .component("DownCircleOutlined", DownCircleOutlined)
  .mount("#app");
app.config.globalProperties.$message = message;
