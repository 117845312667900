<template>
  <a-modal
    :visible="visible"
    title="目录配置"
    destroyOnClose
    :maskClosable="false"
    @ok="handleOk"
    @cancel="handleCancel"
    width="700px"
  >
    <a-form
      ref="form"
      :model="formState"
      name="form"
      :validateOnRuleChange="false"
      :label-col="{ span: 5 }"
      :wrapper-col="{ span: 19 }"
      autocomplete="off"
      scrollToFirstError
    >
      <a-form-item label="选择产品" name="products">
        <a-select
          ref="select"
          mode="multiple"
          :options="products"
          :field-names="{ label: 'name', value: 'id' }"
          optionFilterProp="name"
          v-model:value="formState.product"
          style="width: 100%"
          maxTagCount="responsive"
        />
      </a-form-item>
      <a-form-item label="选择授权品牌" name="brandId">
        <a-select
          ref="select"
          mode="multiple"
          v-model:value="formState.brand"
          :options="licensedBrands"
          :field-names="{ label: 'name', value: 'id' }"
          optionFilterProp="name"
          style="width: 100%"
        />
      </a-form-item>
      <a-form-item label="表格排序">
        <a-table :dataSource="formState.items" :columns="columns" size="small" :pagination="false" />
      </a-form-item>
    </a-form>
  </a-modal>
</template>
<script>
import { ref, watch } from "vue";
export default {
  name: "CatalogueContentForm",
  components: {},
  props: ["visible", "formData", "products", "licensedBrands"],
  setup(props) {
    const formState = ref(props.formData);
    watch(
      () => props.formData,
      (newVal) => {
        formState.value = newVal;
      }
    );

    watch(
      () => formState.value.product,
      (newVal) => {
        if(!formState.value.items) return
        const items = formState.value.items.filter((i) => i.contentType !== "product" || newVal.includes(i.id));
        const pushItems = newVal
          .filter((i) => !items.find((j) => j.contentType === "product" && j.id === i))
          .map((id) => ({ id, contentType: "product" }));
        items.push(...pushItems);
        formState.value = { ...formState.value, items };
      }
    );

    watch(
      () => formState.value.brand,
      (newVal) => {
        if(!formState.value.items) return
        const items = formState.value.items.filter((i) => i.contentType !== "brand" || newVal.includes(i.id));
        const pushItems = newVal
          .filter((i) => !items.find((j) => j.contentType === "brand" && j.id === i))
          .map((id) => ({ id, contentType: "brand" }));
        items.push(...pushItems);
        formState.value = { ...formState.value, items };
      }
    );

    return {
      formState,
      form: ref(null),
      columns: [
        {
          title: "ID",
          dataIndex: "id",
        },
        {
          title: "类型",
          dataIndex: "contentType",
          customRender: ({ value }) => {
            return value === "brand" ? "品牌" : "产品";
          },
        },
        {
          title: "名称",
          dataIndex: "id",
          key: "name",
          customRender: ({ record }) => {
            if (record.contentType === "brand") {
              const { name } = props.licensedBrands.find((i) => i.id === record.id) || {};
              return name;
            } else {
              const { name } = props.products.find((i) => i.id === record.id) || {};
              return name;
            }
          },
        },
        {
          title: "上移",
          key: "up",
          customRender: ({ index: i }) => {
            return (
              <up-circle-outlined
                class="pointer"
                onClick={() => {
                  if (i === 0) return;
                  const items = [...formState.value.items];
                  [items[i], items[i - 1]] = [items[i - 1], items[i]];
                  formState.value = { ...formState.value, items };
                }}
              />
            );
          },
        },
        {
          title: "下移",
          key: "down",
          customRender: ({ index: i }) => {
            return (
              <down-circle-outlined
                class="pointer"
                onClick={() => {
                  if (i === formState.value.items.length - 1) return;
                  const items = [...formState.value.items];
                  [items[i], items[i + 1]] = [items[i + 1], items[i]];
                  formState.value = { ...formState.value, items };
                }}
              />
            );
          },
        },
      ],
    };
  },

  methods: {
    handleOk() {
      this.form
        .validateFields()
        .then((res) => {
          let formState = Object.assign(this.formState, res);
          let items = formState.items;
          formState = {
            gigaServiceId: formState.gigaServiceId,
            items,
          };
          this.$emit("formOk", formState);
        })
        .catch((err) => {
          console.error(err);
          this.$message.error(err.errorFields[0].errors[0]);
        });
    },
    handleCancel() {
      this.$emit("formCancel");
    },
  },
};
</script>
