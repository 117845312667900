export const gigaCataloguesQuery = () => {
  return {
    query: `query {
      catalogues(fetchAll:true) {
        id
        isPublished
        displayOrder
        gigaService {
          id
          name
          icon {
            default
            selected
          }
        }
        content {
          id
          name
          coverImage {
            url
            id
          }
          description
          price
          category
          contentType
        }
      }
    }`
  }
}

export const articlesQuery = () => {
  return {
    query: `query {
      articles(fetchAll:true) {
        id
        title
        author
        content
        coverImage {
          id
          url
        }
        images {
          id
          url
          gotoUrl
        }
        video {
          id
          url
        }
        products {
          id
          name
        }
        isPublished
        displayOrder
      }
    }`
  }
}

export const articleQuery = () => {
  return {
    query: `query($id: ObjectId!) {
      article(id: $id) {
        id
        title
        author
        content
        coverImage {
          id
          url
        }
        images {
          id
          url
          gotoUrl
        }
        products {
          id
          name
        }
        video {
          id
          url
        }
        isPublished
      }
    }`
  }
}

export const gigaServicesQuery = () => {
  return {
    query: `query {
      gigaServices {
        id
        name
      }
    }`
  }
}

export const vehiclesQuery = () => {
  return {
    query: `query {
      vehicles {
        id
        name
      }
    }`
  }
}

export const vehicleBrandsQuery = () => {
  return {
    query: `query {
      vehicleBrands(fetchAll:true) {
        id
        name
        displayOrder
        isPublished
        logo {
          url
          id
        }
        vehicles {
          id
          name
          category
          isPublished
          image {
            url
            id
          }
          displayOrder
          brand {
            id
          }
        }
      }
    }`
  }
}

export const licensedBrandsQuery = () => {
  return {
    query: `query {
      licensedBrands(fetchAll:true) {
        id
        name
        wrapFilmPrice
        isPublished
        logo {
          id
          url
        }
        bannerImage {
          id
          url
        }
        description
        gigaServices {
          id
          name
        }
        filmCategories {
          id
          name
          displayOrder
        }
        displayOrder
      }
    }`
  }
}

export const bannerAdQuery = () => {
  return {
    query: `query {
      bannerAd(fetchAll:true) {
        media {
          id
          url
        }
        gotoUrl
        gotoType
        id
        title
        subTitle
        displayOrder
        positions
        isPublished
      }
    }`
  }
}

export const generateQrCodeQuery = () => {
  return {
    query: `query {
      generateQrCode {
        codeId
        image
      }
    }`
  }
}

export const qrCodeStatusQuery = () => {
  return {
    query: `query($codeId: String!) {
      qrCodeStatus(codeId: $codeId) {
        status
        sessionToken
      }
    }`
  }
}

export const casebooksQuery = () => {
  return {
    query: `query(
      $brandId: ObjectId
      $color: String
      $gigaServiceId: ObjectId
      $keyword: String
      $limit: Int! = 100
      $offset: Int! = 0
      $vehicleId: ObjectId
    ) {
      total
      casebooks(
        fetchAll: true
        brandId: $brandId
        color: $color
        gigaServiceId: $gigaServiceId
        keyword: $keyword
        limit: $limit
        offset: $offset
        vehicleId: $vehicleId
      ) {
        id
        vid
        title
        uploader
        photos {
          url
          id
        }
        product {
          id
          name
        }
        products {
          id
          name
        }
        vehicle {
          id
          name
        }
        displayOrder
        isPublished
        constructedFromGiga
        coverImage {
          id
          url
        }
        price
        shop
      }
    }`
  }
}


export const casebooksTableQuery = () => {
  return {
    query: `query(
      $keyword: String
      $paginate: Paginate
    ) {
      casebooksForAdmin(
        keyword: $keyword
        paginate: $paginate
      ) {
        pageInfo {
          total
        }
        data {
          id
          vid
          title
          uploader
          photos {
            url
            id
          }
          product {
            id
            name
          }
          products {
            id
            name
          }
          vehicle {
            id
            name
          }
          displayOrder
          isPublished
          constructedFromGiga
          coverImage {
            id
            url
          }
          price
          shop
        }
      }
    }`
  }
}

export const vehicleFilmCategoryQuery = () => {
  return {
    query: `query {
      vehicleFilmCategory(fetchAll:true) {
        id
        name
        displayOrder
        isPublished
      }
    }`
  }
}

export const vehicleFilmsQuery = () => {
  return {
    query: `query {
      vehicleFilms(fetchAll:true)  {
        id
        name
        isPublished
        sample {
          id
          url
        }
        color
        isNew
        displayOrder
        filmCategory {
          id
          name
        }
      }
    }`
  }
}

export const outlookDiyWheelQuery = () => {
  return {
    query: `query {
      outlookDiy {
        wheelItems(fetchAll: true) {
          id
          wheel {
            id
            name
            sample {
              id
              url
            }
            displayOrder
            isPublished
          }
          isPublished
          carType
          quickView {
            viewType
            image {
              id
              url
            }
          }
        }
        vehicleWheels(fetchAll: true) {
          id
          size
          name
          carType
          color
          isPublished
          sample {
            id
            url
          }
          displayOrder
        }
      }
    }`
  }
}

export const outlookDiyQuery = () => {
  return {
    query: `query {
      outlookDiy {
        items(fetchAll:true) {
          id
          isPublished
          
          film {
            id
          }
          quickView {
            viewType
            image {
              id
              url
            }
          }
          carType
        }
        vehicleFilms(fetchAll:true)  {
          id
          name
          isPublished
          sample {
            id
            url
          }
          color
          isNew
          displayOrder
          filmCategory {
            id
            name
          }
        }
      }
    }`
  }
}

export const choiceQuery = () => {
  return {
    query: `query {
      choice {
        gigaShop {
          name
          value
        }
        gigaServiceManagers {
          name
          value
        }
        userTypes {
          name
          value
        }
      }
    }`
  }
}

export const shopsQuery = () => {
  return {
    query: `query {
      shops(fetchAll: true) {
        id
        coverImage {
          id
          url
        }
        name
        isPartnerShop
        phoneNum
        address
        isPublished
        displayOrder
        isInBusiness
        wechatQrcode
        description
        city
        location {
          latitude
          longitude
        }
        locationMapImage {
          id
          url
        }
        images {
          id
          url
        }
        title
      }
    }`
  }
}

export const productsQuery = () => {
  return {
    query: `query(
      $brandId: ObjectId
      $filmCategoryId: ObjectId
      $gigaServiceId: ObjectId
      $limit: Int! = 100
      $keyword: String
      $offset: Int! = 0
      $inCatalogue: Boolean  
    ) {
      products(
        keyword: $keyword
        fetchAll:true
        brandId: $brandId
        filmCategoryId: $filmCategoryId
        gigaServiceId: $gigaServiceId
        limit: $limit,
        offset: $offset
        inCatalogue: $inCatalogue
      ) {
        youzanProducts {
          id
          name
        }
        category
        id
        name
        price
        images {
          id
          url
        }
        coverImage {
          id
          url
        }
        video {
          id
          url
        }
        brand {
          name
          id
        }
        vehicleFilm {
          name
          id
        }
        gigaService {
          name
          id
        }
        vehicles {
          name
          id
        }
        description
        displayOrder
        subTitle
        isPublished
      }
    }`
  }
}

export const editVehicleBrandQuery = () => {
  return {
    query: `mutation(
      $id: ObjectId
      $logoId: ObjectId
      $name: String
      $isDeleted: Boolean
      $isPublished: Boolean
      $displayOrder: Int
    ) {
      editVehicleBrand(
        id: $id
        logoId: $logoId
        name: $name
        isDeleted: $isDeleted
        isPublished: $isPublished
        displayOrder: $displayOrder
      ) {
        id
      }
    }`
  }
}

export const editVehicleQuery = () => {
  return {
    query: `mutation(
      $id: ObjectId
      $brandId: ObjectId
      $imageId: ObjectId
      $name: String
      $category: String
      $isDeleted: Boolean
      $isPublished: Boolean
      $displayOrder: Int
    ) {
      editVehicle(
        id: $id
        brandId: $brandId
        imageId: $imageId
        name: $name
        category: $category
        isDeleted: $isDeleted
        isPublished: $isPublished
        displayOrder: $displayOrder
      ) {
        id
      }
    }`
  }
}

export const editGigaServiceQuery = () => {
  return {
    query: `mutation(
      $id: ObjectId
      $name: String
      $icon: IconInput
      $isDeleted: Boolean
      $isPublished: Boolean
    ) {
      editGigaService(
        id: $id
        icon: $icon
        name: $name
        isDeleted: $isDeleted
        isPublished: $isPublished
      ) {
        id
      }
    }`
  }
}

export const configureCatalogueQuery = () => {
  return {
    query: `mutation(
      $gigaServiceId: ObjectId!
      $items: [CatalogueItemInput!]
      $isDeleted: Boolean
      $isPublished: Boolean
      $displayOrder: Int
    ) {
      configureCatalogue(
        gigaServiceId: $gigaServiceId
        items: $items
        isDeleted: $isDeleted
        isPublished: $isPublished
        displayOrder: $displayOrder
      ) {
        isOk
      }
    }`
  }
}

export const editFilmCategoryQuery = () => {
  return {
    query: `mutation(
      $id: ObjectId
      $name: String
      $isDeleted: Boolean
      $isPublished: Boolean
      $displayOrder: Int
    ) {
      editFilmCategory(
        id: $id
        name: $name
        isDeleted: $isDeleted
        isPublished: $isPublished
        displayOrder: $displayOrder
      ) {
        id
      }
    }`
  }
}

export const editFilmQuery = () => {
  return {
    query: `mutation(
      $id: ObjectId
      $name: String
      $color: String
      $sampleId: ObjectId
      $filmCategoryId: ObjectId
      $isDeleted: Boolean
      $isPublished: Boolean
      $displayOrder: Int
    ) {
      editFilm(
        id: $id
        name: $name
        color: $color
        sampleId: $sampleId
        filmCategoryId: $filmCategoryId
        isDeleted: $isDeleted
        isPublished: $isPublished
        displayOrder: $displayOrder
      ) {
        id
      }
    }`
  }
}

export const editLicenseBrandQuery = () => {
  return {
    query: `mutation(
      $id: ObjectId
      $name: String
      $logoId: ObjectId
      $coverImageId: ObjectId
      $bannerImageId: ObjectId
      $description: String
      $filmCategoryIds: [ObjectId!]
      $isDeleted: Boolean
      $isPublished: Boolean
      $displayOrder: Int
      $wrapFilmPrice: String
    ) {
      editLicenseBrand(
        id: $id
        name: $name
        logoId: $logoId
        wrapFilmPrice: $wrapFilmPrice
        description: $description
        coverImageId: $coverImageId
        bannerImageId: $bannerImageId
        filmCategoryIds: $filmCategoryIds
        isDeleted: $isDeleted
        isPublished: $isPublished
        displayOrder: $displayOrder
      ) {
        id
      }
    }`
  }
}

export const editProductQuery = () => {
  return {
    query: `mutation(
      $id: ObjectId
      $coverImageId: ObjectId
      $name: String
      $price: String
      $subTitle: String
      $imageIds: [ObjectId!]
      $videoId: ObjectId
      $brandId: ObjectId
      $imageId: ObjectId
      $description: String
      $vehicleIds: [ObjectId!]
      $vehicleFilmId: ObjectId
      $isDeleted: Boolean
      $isPublished: Boolean
      $displayOrder: Int
      $gigaServiceId: ObjectId
      $youzanIds: [ObjectId!]
      $category: String
    ) {
      editProduct(
        id: $id
        youzanIds: $youzanIds
        coverImageId: $coverImageId
        name: $name
        price: $price
        category: $category
        subTitle: $subTitle
        imageIds: $imageIds
        videoId: $videoId
        brandId: $brandId
        imageId: $imageId
        description: $description
        vehicleIds: $vehicleIds
        vehicleFilmId: $vehicleFilmId
        isDeleted: $isDeleted
        isPublished: $isPublished
        displayOrder: $displayOrder
        gigaServiceId: $gigaServiceId
      ) {
        id
      }
    }`
  }
}

export const editCasebookQuery = () => {
  return {
    query: `mutation(
      $id: ObjectId
      $title: String
      $coverImageId: ObjectId
      $photoIds: [ObjectId!]
      $productId: ObjectId
      $productIds: [ObjectId!]
      $vehicleId: ObjectId
      $workerSheetNum: String
      $price: String
      $shop: String
      $uploader: String
      $isDeleted: Boolean
      $isPublished: Boolean
      $displayOrder: Int
      $constructedFromGiga: Boolean
      $vid: String
      $vehicleFilmId: ObjectId
    ) {
      editCasebook(
        id: $id
        vid: $vid
        coverImageId: $coverImageId
        uploader: $uploader
        title: $title
        price: $price
        photoIds: $photoIds
        productId: $productId
        productIds: $productIds
        vehicleId: $vehicleId
        workerSheetNum: $workerSheetNum
        shop: $shop
        isDeleted: $isDeleted
        isPublished: $isPublished
        displayOrder: $displayOrder
        constructedFromGiga: $constructedFromGiga
        vehicleFilmId: $vehicleFilmId
      ) {
        id
      }
    }`
  }
}

export const editOutlookDiyQuery = () => {
  return {
    query: `mutation(
      $id: ObjectId
      $filmId: ObjectId
      $quickView: [QuickViewInput!]
      $carType: DiyCarType
      $isDeleted: Boolean
      $isPublished: Boolean
    ) {
      editOutlookDiy(
        id: $id
        filmId: $filmId
        quickView: $quickView
        carType: $carType
        isPublished: $isPublished
        isDeleted: $isDeleted
      ) {
        id
      }
    }`
  }
}

export const editOutlookDiyWheelQuery = () => {
  return {
    query: `mutation(
      $id: ObjectId
      $wheelId: ObjectId
      $quickView: [QuickViewInput!]
      $carType: DiyCarType
      $isDeleted: Boolean
      $isPublished: Boolean
      $displayOrder: Int
    ) {
      editOutlookDiyWheel(
        id: $id
        wheelId: $wheelId
        quickView: $quickView
        carType: $carType
        isPublished: $isPublished
        isDeleted: $isDeleted
        displayOrder: $displayOrder
      ) {
        id
      }
    }`
  }
}

export const editWheelQuery = () => {
  return {
    query: `mutation(
      $id: ObjectId
      $name: String
      $size: Int
      $sampleId: ObjectId
      $isDeleted: Boolean
      $isPublished: Boolean
      $displayOrder: Int
      $color: String
      $carType: DiyCarType
    ) {
      editWheel(
        carType: $carType
        color: $color
        id: $id
        name: $name
        size: $size
        sampleId: $sampleId
        isPublished: $isPublished
        isDeleted: $isDeleted
        displayOrder: $displayOrder
      ) {
        id
      }
    }`
  }
}

export const editArticleQuery = () => {
  return {
    query: `mutation(
      $id: ObjectId
      $title: String
      $author: String
      $content: String
      $coverImageId: ObjectId
      $videoId: ObjectId
      $imageIds: [ObjectId!]
      $isDeleted: Boolean
      $isPublished: Boolean
      $displayOrder: Int
      $productIds: [ObjectId!]
    ) {
      editArticle(
        id: $id
        title: $title
        author: $author
        content: $content
        coverImageId: $coverImageId
        videoId: $videoId
        imageIds: $imageIds
        isDeleted: $isDeleted
        isPublished: $isPublished
        displayOrder: $displayOrder
        productIds: $productIds
      ) {
        id
      }
    }`
  }
}

export const editBannerAdQuery = () => {
  return {
    query: `mutation(
      $id: ObjectId
      $title: String
      $subTitle: String
      $mediaId: ObjectId
      $gotoUrl: String
      $gotoType: AdGotoType
      $positions: [BannerAdPosition!]
      $isPublished: Boolean
      $displayOrder: Int
      $isDeleted: Boolean
    ) {
      editBannerAd(
        isDeleted: $isDeleted
        id: $id
        title: $title
        subTitle: $subTitle
        mediaId: $mediaId
        gotoUrl: $gotoUrl
        gotoType: $gotoType
        positions: $positions
        isPublished: $isPublished
        displayOrder: $displayOrder
      ) {
        id
      }
    }`
  }
}

export const editShopQuery = () => {
  return {
    query: `mutation(
      $id: ObjectId
      $coverImageId: ObjectId
      $imageIds: [ObjectId!]
      $name: String
      $phoneNum: String
      $title: String
      $description: String
      $address: String
      $isDeleted: Boolean
      $isPublished: Boolean
      $isPartnerShop: Boolean
      $displayOrder: Int
      $location: LocationInput
      $wechatQrcode: String
      $city: String
      $locationMapImage: ObjectId
    ) {
      editShop(
        id: $id
        city: $city
        locationMapImage: $locationMapImage
        coverImageId: $coverImageId
        imageIds: $imageIds
        name: $name
        phoneNum: $phoneNum
        title: $title
        description: $description
        address: $address
        isDeleted: $isDeleted
        isPublished: $isPublished
        isPartnerShop: $isPartnerShop
        displayOrder: $displayOrder
        location: $location
        wechatQrcode: $wechatQrcode
      ) {
        id
      }
    }`
  }
}

export const editWordSkillQuery = () => {
  return {
    query: `mutation(
      $id: ObjectId
      $content: String
      $scene: WordSkillScene
      $displayOrder: Int
      $isDeleted: Boolean
      $isPublished: Boolean
      $title: String
    ) {
      editWordSkill(
        id: $id,
        content: $content
        title: $title
        scene: $scene
        displayOrder: $displayOrder
        isDeleted: $isDeleted
        isPublished: $isPublished
      ) {
        id
      }
    }`
  }
}

export const wordSkillsQuery = () => {
  return {
    query: `query($keyword: String) {
      wordSkills(
        fetchAll: true
        keyword: $keyword
      ) {
        id
        content
        scene
        isPublished
        displayOrder
        createdAt
        title
      }
    }`
  }
}

export const wordSkillQuery = () => {
  return {
    query: `query($id: ObjectId!) {
      wordSkill(
        id: $id
      ) {
        id
        content
        scene
        isPublished
        displayOrder
        createdAt
        title
      }
    }`
  }
}

export const editPriceTableQuery = () => {
  return {
    query: `mutation(
      $id: ObjectId
      $title: String
      $content: String
      $isDeleted: Boolean
      $isPublished: Boolean
      $displayOrder: Int
      $releasedAt: Int
    ) {
      editPriceTable(
        id: $id
        title: $title
        content: $content
        isDeleted: $isDeleted
        isPublished: $isPublished
        displayOrder: $displayOrder
        releasedAt: $releasedAt
      ) {
        id
      }
    }`
  }
}

export const priceTableQuery = () => {
  return {
    query: `query($id: ObjectId!) {
      priceTable(
        id: $id
      ) {
        id
        title
        content
        isPublished
        displayOrder
        releasedAt
      }
    }`
  }
}

export const priceTablesQuery = () => {
  return {
    query: `query {
      priceTables(
        fetchAll: true
      ) {
        id
        title
        content
        isPublished
        displayOrder
        releasedAt
      }
    }`
  }
}

export const specialUsersQuery = () => {
  return {
    query: `query($userType: UserType!) {
      specialUsers(userType: $userType) {
        department
        name
      }
    }`
  }
}

export const gigaManagerCasebooksQuery = () => {
  return {
    query: `query(
      $endAt: Timestamp
      $shop: String
      $startAt: Timestamp
    ) {
      gigaManagerCasebooks(
        endAt: $endAt
        shop: $shop
        startAt: $startAt
      ) {
        downloadUrl
        data {
          id
          shop
          uploader
          title
          vehicle {
            id
            name
          }
        }
      }
    }`
  }
}

export const youzanProductsQuery = () => {
  return {
    query: `query(
      $keyword: String
    ) {
      youzanProducts(
        keyword: $keyword
      ) {
        id
        name
        skuName
        price
      }
    }`
  }
}

export const loginByFeishuQuery = () => {
  return {
    query: `mutation(
      $code: String!
    ) {
      loginByFeishu(
        code: $code
      ) {
        sessionToken
        isNewbie
        lcSessionToken
      }
    }`
  }
}


// coupons
export const filterUserCount = () => {
  return {
    query: `query($matchRuleId: String!) {
      userMatchRuleInfo {
        matchUsers(matchRuleId: $matchRuleId) {
          users {
            objectId
          }
          count
        }
      }
    } `
  }
}

export const addOfflineCouponActivity = () => {
  return {
    query: `mutation(
      $condition: Int!
      $denominations: Int
      $description: String!
      $discount: Int
      $expireNotice: ExpireNotice!
      $preferentialType: PreferentialType!
      $productIds: [String]
      $rangeType: RangeType!
      $title: String!
      $totalQty: Int!
      $validEndTime: Timestamp!
      $validStartTime: Timestamp!
      $isLimit: Int!
      $fixedBeginTerm: Int!
      $fixedTerm: Int!
      $dateType: DateType!
    ) {
      addOfflineCouponActivity(
        isShare: no
        rangeType: $rangeType
        condition: $condition
        denominations: $denominations
        description: $description
        discount: $discount
        expireNotice: $expireNotice
        preferentialType: $preferentialType
        productIds: $productIds
        title: $title
        totalQty: $totalQty
        validEndTime: $validEndTime
        validStartTime: $validStartTime
        isLimit: $isLimit
        fixedBeginTerm: $fixedBeginTerm
        fixedTerm: $fixedTerm
        dateType: $dateType
      ) {
        couponActivity {
          objectId
        }
      }
    }`
  }
}

export const updateOfflineCouponActivity = () => {
  return {
    query: `mutation(
      $objectId: String!
      $condition: Int
      $dateType: DateType
      $denominations: Int
      $description: String
      $discount: Int
      $expireNotice: ExpireNotice
      $fixedBeginTerm: Int
      $fixedTerm: Int
      $isInvalid: Int
      $isLimit: Int
      $isShare: IsShare
      $preferentialType: PreferentialType
      $productIds: [String]
      $rangeType: RangeType
      $title: String
      $totalQty: Int
      $validEndTime: Timestamp
      $validStartTime: Timestamp
    ) {
      updateOfflineCouponActivity(
        objectId: $objectId
        condition: $condition
        dateType: $dateType
        denominations: $denominations
        description: $description
        discount: $discount
        expireNotice: $expireNotice
        fixedBeginTerm: $fixedBeginTerm
        fixedTerm: $fixedTerm
        isInvalid: $isInvalid
        isLimit: $isLimit
        isShare: $isShare
        preferentialType: $preferentialType
        productIds: $productIds
        rangeType: $rangeType
        title: $title
        totalQty: $totalQty
        validEndTime: $validEndTime
        validStartTime: $validStartTime
      ) {
        couponActivity {
          objectId
        }
      }
    }`
  }
}

export const refreshYouzanCouponActivity = () => {
  return {
    query: `mutation($youzanCaId: String!) {
      refreshYouzanCouponActivity(youzanCaId: $youzanCaId) {
        couponActivity {
          objectId
        }
      }
    }`
  }
}

export const createUserCoupons = () => {
  return {
    query: `mutation(
      $couponActivityId: String!
      $isCron: Boolean = false
      $sendMsg: Boolean = false
      $matchRuleId: String!
      $title: String!
     ) {
      createUserCoupons(
        couponActivityId: $couponActivityId
        isCron: $isCron
        sendMsg: $sendMsg
        matchRuleId: $matchRuleId
        title: $title
      ) {
        isOk
      }
    }`
  }
}

export const updateCouponSender = () => {
  return {
    query: `mutation(
      $objectId: String!
      $sendMsg: Boolean
      $matchRuleId: String
      $title: String
      $status: SendStatus
     ) {
      updateCouponSender(
        objectId: $objectId
        status: $status
        sendMsg: $sendMsg
        matchRuleId: $matchRuleId
        title: $title
      ) {
        couponSender {
          objectId
        }
      }
    }`
  }
}

export const matchRules = () => {
  return {
    query: `query {
      userMatchRuleInfo {
        matchRules(limit: 999) {
          priority
          objectId
          hasPhone
          isTeslaAuthBound
          isVehicleBound
          hasVehicleOrder
          vehicleOrderStatus
          title
          userTags {
            objectId
            tagName
            displayName
          }
          registerTimePeriod
          orderStatusChangedPeriod
          couponSenders {
            objectId
            title
          }
          adMedias {
            objectId
            adType
            title
          }
        }
      }
    }`
  }
}

export const addUserMatchRule = () => {
  return {
    query: `mutation(
      $priority: Int!
      $hasPhone: Boolean
      $hasVehicleOrder: Boolean
      $isTeslaAuthBound: Boolean
      $isVehicleBound: Boolean
      $registerTimePeriod: [Timestamp]
      $orderStatusChangedPeriod: [Timestamp]
      $tagNames: [String]
      $title: String
      $vehicleOrderStatus: OrderStatus) {
      addUserMatchRule(
        priority: $priority
        title: $title
        hasPhone: $hasPhone
        hasVehicleOrder: $hasVehicleOrder
        isTeslaAuthBound: $isTeslaAuthBound
        isVehicleBound: $isVehicleBound
        orderStatusChangedPeriod: $orderStatusChangedPeriod
        registerTimePeriod: $registerTimePeriod
        tagNames: $tagNames
        vehicleOrderStatus: $vehicleOrderStatus
      ) {
        userMatchRule {
          objectId
        }
      }
    }`
  }
}

export const updateUserMatchRule = () => {
  return {
    query: `mutation(
      $priority: Int
      $hasPhone: Boolean
      $hasVehicleOrder: Boolean
      $isTeslaAuthBound: Boolean
      $isVehicleBound: Boolean
      $objectId: String!
      $orderStatusChangedPeriod: [Timestamp]
      $registerTimePeriod: [Timestamp]
      $tagNames: [String]
      $title: String
      $vehicleOrderStatus: OrderStatus) {
      updateUserMatchRule(
        priority: $priority
        title: $title
        hasPhone: $hasPhone
        hasVehicleOrder: $hasVehicleOrder
        isTeslaAuthBound: $isTeslaAuthBound
        isVehicleBound: $isVehicleBound
        registerTimePeriod: $registerTimePeriod
        orderStatusChangedPeriod: $orderStatusChangedPeriod
        tagNames: $tagNames
        vehicleOrderStatus: $vehicleOrderStatus
        objectId: $objectId
      ) {
        userMatchRule {
          objectId
        }
      }
    }`
  }
}

export const couponSenders = () => {
  return {
    query: `query {
      couponInfo {
        couponSenders {
          objectId
          status
          sendUserCount
          latestStartAt
          latestEndAt
          sendMsg
          isCron
          title
          couponActivity {
            objectId
            title
          }
          userMatchRule {
            objectId
            title
          }
        } 
      }
    }`
  }
}

export const couponActivities = () => {
  return {
    query: `query($limit: Int, $offset: Int) {
      couponInfo {
        couponActivities(limit: $limit, offset: $offset, createType:offline) {
          objectId
          title
          discount
          denominations
          preferentialType
          condition
          description
          fixedTerm
          fixedBeginTerm
          dateType
          isLimit
          validStartTime
          validEndTime
          isInvalid
          isForbidPreference
          couponCreated
          isShare
          youzanProducts {
            buyUrl
            title
            subTitle
            activityPrice
            price
          }
          offlineProducts {
            objectId
            title
            description
            price
            imageUrl
            videoUrl
            gotoUrl
            displayOrder
            hotDegree
            displayAtHomepage
          }
          createType
          expireNotice
          totalQty
          totalTake
          totalUsed
          couponSender {
            status
          }
        }
        activityCount
      }
    }`
  }
}

export const youzanCouponActivities = () => {
  return {
    query: `query {
      couponInfo {
        youzanCouponActivities(status: on, limit: 99) {
          total
          activities {
            title
            onlineCouponActivityId
          }
        }
      }      
    }`
  }
}

export const configureReferralExpertQuery = () => {
  return {
    query: `mutation(
      $nickname: String!
      $phoneNum: String!
    ) {
      configureReferralExpert(
        phoneNum: $phoneNum
        nickname: $nickname
      ) {
        userId
        h5
        miniProgram
      }
    }`
  }
}

export const updateReferralExpertQuery = () => {
  return {
    query: `mutation(
      $userId: ObjectId!
      $welcomeMsg: String
    ) {
      updateReferralExpert(
        userId: $userId
        welcomeMsg: $welcomeMsg
      ) {
        isOk
      }
    }`
  }
}

export const referralActivityExpertsQuery = () => {
  return {
    query: `query {
      referralActivityExperts {
        id
        nickname
        phoneNum
        referralWelcomeMsg
        referralH5Link
        referralMiniProgramLink
      }      
    }`
  }
}


