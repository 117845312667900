<template>
  <div>
    <div class="operations">
      <div style="flex:1"></div>
      <a-button @click="toAdd">添加</a-button>
    </div>
    <GigaTable :gData="dataSource" :gColumns="columns" />
    <CouponForm :visible="showForm"  /> 
  </div>
</template>
<script>
import { getCouponActivities, updateCouponActivity } from '../http/api'
import GigaTable from '../components/GigaTable.vue'
import CouponForm from '../components/form/CouponForm.vue'
export default {
  components: {
    GigaTable,
    CouponForm
  },
  data() {
    return {
      showForm: false,
      totalCount: 0,
      page: 1,
      limit: 100,
      formState: {},
      dataSource: [],
      columns: [{
        title: '优惠券名称',
        dataIndex: 'title',
        key: 'title',
        width: 160,
      }, {
        title: '优惠券类型',
        dataIndex: 'preferentialType',
        key: 'preferentialType',
        customRender: (val) => <span v-html={val.value == 1 ? '满减券' : '折扣券'} />,
        width: 120,
      }, {
        title: '减免/折扣',
        dataIndex: 'discount',
        key: 'discount',
        customRender: (val) => <span v-html={val.record.preferentialType == 2 ? val.record.discount + '%' : val.record.preferentialType == 1 ? val.record.denominations / 100 + '（元）' : ''} />,
        width: 120,
      }, {
        title: '门槛金额',
        dataIndex: 'condition',
        key: 'condition',
        width: 100,
      }, {
        title: '限领次数',
        dataIndex: 'isLimit',
        key: 'isLimit',
        width: 90,
      }, {
        title: '优惠券状态',
        dataIndex: 'isInvalid',
        key: 'isInvalid',
        customRender: (val) => <span v-html={val.value == 1 || (new Date(val.record.validEndTime).getTime() < new Date().getTime()) ? '已失效' : '有效'} />,
      }, {
        title: '操作',
        dataIndex: 'operation',
        key: 'operation',
        customRender: (val) => {
          return (
            <div>
              <a-dropdown trigger={['click']} overlay={(
                <a-menu>
                  <a-menu-item onClick={() => this.edit(val.record)}>
                    <span>编辑</span>
                  </a-menu-item>
                  <a-menu-item onClick={() => this.edit(val.record, 'del')}>
                    <span>使失效</span>
                  </a-menu-item>
                </a-menu>
              )}>
                <a-button>操作</a-button>
              </a-dropdown>
            </div>
          )
        }
      }]
    }
  },
  created() {
    this.getCouponActivities()
  },
  methods: {
    handleOk(formState) {
      console.log(formState)
      updateCouponActivity({ variables: formState }).then(res => {
        console.log(res)
        this.handleCancel()
        this.getCouponActivities()
      }).catch(err => {
        console.log(err)
      })
    },
    edit(record, type) {
      if (type == 'del') {
        console.log(record)
        this.handleOk({ objectId: record.objectId, isInvalid: 1 })
      } else {
        // this.$router.push(`/edit-article?id=${record.id}`)
      }
    },
    handleCancel() {
      this.showForm = false
      this.formState = {}
    },
    toAdd() {
      // this.$router.push(`/edit-article`)
    },
    copyLink(record) {
      let value = `/pages/article/article.html?id=${record.id}`
      if (navigator.clipboard) {
        navigator.clipboard.writeText(value);
        this.$message.success('已复制到剪切板')
      }
    },
    getCouponActivities() {
      getCouponActivities({variables: {
        offset: (this.page - 1) * this.limit,
        limit: this.limit
      }}).then(res => {
        console.log(res)
          this.totalCount = res.data.couponInfo.activityCount
          this.dataSource = res.data.couponInfo.couponActivities
      }).catch(err => {
        console.log(err)
      })
    }
  },
}
</script>
<style lang="scss" scoped></style>