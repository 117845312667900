<template>
  <a-table
    :columns="columns"
    :data-source="data"
    :scroll="{ y: scrollY, x: tScrollX }"
    :row-key="(record) => record.id"
    :pagination="tPagination"
    :style="{ height: tabelHeight + 'px' }"
    :row-selection="tRowSelection"
    :loading="tLoading"
  >
    <template v-if="innerColumns" #expandedRowRender="{ record }">
      <a-table :columns="innerColumns" :data-source="record.innerData" :pagination="false" />
    </template>
  </a-table>
</template>
<script>
import { defineComponent, ref, watch } from 'vue';

export default defineComponent({
  props: ['gData', 'gColumns', 'gInnerColumns', 'rowSelection', 'pagination', 'loading', 'scrollX'],
  setup(props) {
    const data = ref(props.gData);
    const columns = ref(props.gColumns);
    const innerColumns = ref(props.gInnerColumns);
    watch(
      () => props.gData,
      (newVal) => (data.value = newVal)
    );
    const getClientHeight = () => {
      let clientHeight = 0;
      if (document.body.clientHeight && document.documentElement.clientHeight) {
        clientHeight =
          document.body.clientHeight < document.documentElement.clientHeight ? document.body.clientHeight : document.documentElement.clientHeight;
      } else {
        clientHeight =
          document.body.clientHeight > document.documentElement.clientHeight ? document.body.clientHeight : document.documentElement.clientHeight;
      }
      return clientHeight;
    };
    const getTabelHeight = () => {
      let tabelHeight = getClientHeight() - 166;
      return tabelHeight;
    };

    const tabelHeight = ref(getTabelHeight());
    const tPagination = ref(props.pagination || false);
    const tLoading = ref(props.loading);
    const scrollY = ref(props.pagination ? (tabelHeight.value - 60) : tabelHeight.value);

    watch(
      () => props.pagination,
      (newVal) => (tPagination.value = newVal)
    );
    watch(
      () => props.loading,
      (newVal) => (tLoading.value = newVal)
    );

    return {
      data,
      columns,
      innerColumns,
      tabelHeight,
      scrollY,
      tRowSelection: props.rowSelection,
      tPagination,
      tLoading,
      tScrollX: ref(props.scrollX),
    };
  },
});
</script>
