<template>
  <div>
    <div class="operations">
      <a-range-picker v-model:value="rangeValue" picker="month" :locale="locale" style="margin-right: 20px" @change="dateChange" />
      <div style="flex:1"></div>
      <a-button @click="downloadGigaManagerCasebooks">导出</a-button>
    </div>
    <giga-table :gData="dataSource" :gColumns="columns" />
  </div>
</template>
<script>
import { getGigaManagerCasebooks } from '../http/api'
import GigaTable from '../components/GigaTable.vue'

export default {
  components: {
    GigaTable
  },
  data() {
    return {
      downloadUrl: '',
      rangeValue: '',
      keywordValue: '',
      showForm: false,
      addItem: false,
      formState: {},
      dataSource: [],
      page: 1,
      vehicles: [],
      products: [],
      gigaShop: [],
      gigaServiceManagers: [],
      columns: [{
        title: 'id',
        dataIndex: 'id',
        key: 'id',
        width: 80
      }, {
        title: '案例标题',
        dataIndex: 'title',
        key: 'title'
      }, {
        title: '车型',
        dataIndex: 'vehicle',
        key: 'vehicle',
        customRender: val => {
          return <span v-html={val.value && val.value.name || ''} />
        }
      }, {
        title: '施工店铺',
        dataIndex: 'shop',
        key: 'shop'
      }, {
        title: '服务经理',
        dataIndex: 'uploader',
        key: 'uploader'
      }]
    }
  },
  created() {
    this.getGigaManagerCasebooks()
  },
  methods: {
    setImg(e, type) {
      let img = e[0]
      let formState = this.formState
      formState[type] = img.url
      this.formState = JSON.parse(JSON.stringify(formState))
    },

    downloadGigaManagerCasebooks() {
      if (this.downloadUrl) {
        window.open(this.downloadUrl)
      }
    },

    dateChange(e, date) {
      if (date.length && date[0]) {
        this.endAt = Math.floor(new Date(date[1]).getTime() / 1000)
        this.startAt = Math.floor(new Date(date[0]).getTime() / 1000)
      } else {
        this.endAt = null
        this.startAt = null
      }
      this.getGigaManagerCasebooks()
    },
    getGigaManagerCasebooks() {
      getGigaManagerCasebooks({variables: {endAt: this.endAt || null, startAt: this.startAt || null}}).then(res => {
        if (res.errors) {
          this.$message.error(res.errors[0].message)
          return
        }
        let dataSource = res.data.gigaManagerCasebooks.data
        this.downloadUrl = res.data.gigaManagerCasebooks.downloadUrl
        this.dataSource = dataSource
      })
    },
  },
}
</script>
<style lang="scss" scoped>
  
</style>