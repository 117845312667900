import axios from 'axios';
import { message } from 'ant-design-vue';

// import AV from 'leancloud-storage'
let baseURL = 'https://giga-api.xiaote.net'
// let baseURL = 'https://stg-lcen.xiaote.net'
axios.defaults.timeout = 90000;
// axios.defaults.baseURL ='https://stg-lcen.xiaote.net'; //填写域名

//http request 拦截器
axios.interceptors.request.use(
  config => {
    // config.data = JSON.stringify(config.data);
    let requestData = config.data;
    console.log(requestData)
    let sessionToken = localStorage.getItem('sessionToken')
    let lcSessionToken = localStorage.getItem('lcSessionToken') || ''
    config.headers = {
      'X-LC-Id': '4Paj7MSbcOPWbUj4qLbPdm4B-gzGzoHsz',
      'X-LC-Key': 'cTBgegHXVcwPhAl4CTcMcMwm',
      'app-platform': 'web',
      'app-version': '0.0.1',
      'X-App-Secret': '9s6XzoDJ27HxHQ7M',
      'session-token': sessionToken,
      'X-Lc-Session': lcSessionToken
    }
    if (!isFormData(requestData)) {
      config.headers['Content-Type'] = 'application/json;charset=UTF-8'
    }
    return config;
  },
  error => {
    return Promise.reject(error);
  }
)

//响应拦截器即异常处理
axios.interceptors.response.use(response => {
  return response
}, err => {
  if (err && err.response) {
    switch (err.response.status) {
      case 400:
        console.log('错误请求')
        break;
      case 401:
        console.log('未授权，请重新登录')
        break;
      case 403:
        console.log('拒绝访问')
        break;
      case 404:
        console.log('请求错误,未找到该资源')
        break;
      case 405:
        console.log('请求方法未允许')
        break;
      case 408:
        console.log('请求超时')
        break;
      case 500:
        console.log('服务器端出错')
        break;
      case 501:
        console.log('网络未实现')
        break;
      case 502:
        console.log('网络错误')
        break;
      case 503:
        console.log('服务不可用')
        break;
      case 504:
        console.log('网络超时')
        break;
      case 505:
        console.log('http版本不支持该请求')
        break;
      default:
        console.log(`连接错误${err.response.status}`)
    }
  } else {
    console.log('连接到服务器失败')
  }
  return Promise.resolve(err.response)
})


export const get = (url, params = {}, config = {}) => {
  url = `${baseURL}${url}`
  return new Promise((resolve,reject) => {
    axios.get(url, {params}, config).then(response => {
      console.log(response)
      if (response && response.data) {
        resolve(response.data)
      } else {
        reject('请求失败')
      }
      // console.log(response)
      // resolve(response.data)
    }).catch(err => {
      reject(err)
    })
  })
}

/**
 * 封装post请求
 * @param url
 * @param data
 * @returns {Promise}
 */

export const post = (url, data = {}) => {
  console.log(data)
  url = `${baseURL}${url}`
  return new Promise((resolve, reject) => {
    axios.post(url, data).then(response => {
      if (response.data.errors) {
        if (response.data.errors[0].code == '-101020') {
          localStorage.clear()
          window.location.hostname !== "localhost" && window.location.reload()
        } else if (response.data.errors[0].code == '-101080') {
          let url = 'https://giga-admin.xiaote.net'
          url = encodeURIComponent(url)
          window.location.href = `https://open.feishu.cn/open-apis/authen/v1/index?app_id=cli_a4d22b0513b8100e&redirect_uri=${url}`
        } else {
          message.error(response.data.errors[0].message)
        }
        reject(response.data)
        return
      }
      resolve(response.data);
    }, err => {
      reject(err)
    })
  })
}

export const post_xt = (url, data = {}) => {
  url = `https://lcen.xiaote.net${url}`
  return new Promise((resolve, reject) => {
    axios.post(url, data).then(response => {
      if (response.data.errors) {
        if (response.data.errors[0].code == '-101020') {
          localStorage.clear()
          window.location.hostname !== "localhost" && window.location.reload()
        } else if (response.data.errors[0].code == '-101080') {
          let url = 'https://giga-admin.xiaote.net'
          url = encodeURIComponent(url)
          window.location.href = `https://open.feishu.cn/open-apis/authen/v1/index?app_id=cli_a4d22b0513b8100e&redirect_uri=${url}`
        } else {
          message.error(response.data.errors[0].message)
        }
        reject(response.data)
        return
      }
      resolve(response.data);
    }, err => {
      reject(err)
    })
  })
}

function isFormData(val) {
  return (typeof FormData !== 'undefined') && (val instanceof FormData);
}