<template>
  <div>
    <div class="operations">
      <div style="flex:1"></div>
      <a-button @click="addVehicleBrandVisible = true">添加</a-button>
    </div>
    <giga-table :gData="dataSource" :gColumns="columns" :gInnerColumns="innerColumns" />
    <GigaShowImage :showImg="isShow" :src="imgUrl" @close="isShow = false" />
    <VehicleBrandForm :visible="addVehicleBrandVisible" :formData="formState" @formCancel="handleCancel" @formOk="handleOk($event, 'vehicleBrandForm')" /> 
    <VehicleForm :visible="addVehicleVisible" :formData="formState" @formCancel="handleCancel" @formOk="handleOk($event, 'vehicleForm')" />
  </div>
</template>
<script>
import { getVehicleBrands, editVehicleBrand, editVehicle } from '../http/api'
import GigaTable from '../components/GigaTable.vue'
import GigaShowImage from '../components/ShowImg.vue'
import VehicleBrandForm from '../components/form/VehicleBrandForm'
import VehicleForm from '../components/form/VehicleForm'
export default {
  components: {
    GigaTable,
    GigaShowImage,
    VehicleBrandForm,
    VehicleForm
  },

  data() {
    return {
      vehicleBrandId: '',
      formState: {},
      addVehicleBrandVisible: false,
      addVehicleVisible: false,
      isShow: false,
      imgUrl: '',
      dataSource: [],
      columns: [{
        title: 'id',
        dataIndex: 'id',
        key: 'id',
        width: 80
      }, {
        title: '品牌',
        dataIndex: 'name',
        key: 'name',
      }, {
        title: 'logo',
        dataIndex: 'logo',
        key: 'logo',
        customRender: (val) => {
          return <img src={val.value && val.value.url || ''} style="width: 60px" onClick={() => this.showImg(val.value.url)} />
        }
      }, {
        title: '排序',
        dataIndex: 'displayOrder',
        key: 'displayOrder',
      }, {
        title: '是否发布',
        dataIndex: 'isPublished',
        key: 'isPublished',
        customRender: val => <span v-html={val.value ? '已发布' : '未发布'} />
      }, {
        title: '操作',
        dataIndex: 'operation',
        key: 'operation',
        width: 200,
        customRender: (val) => {
          return (
            <div>
              <a-dropdown trigger={['click']} overlay={(
                <a-menu>
                  <a-menu-item onClick={() => this.editVehicleBrand(val.record)}>
                    <span>编辑</span>
                  </a-menu-item>
                  <a-menu-item onClick={() => this.editVehicleBrand(val.record, 'publish')}>
                    <span v-html={val.record.isPublished ? '取消发布' : '发布'}></span>
                  </a-menu-item>
                  <a-menu-item onClick={() => this.editVehicleBrand(val.record, 'del')}>
                    <span>删除</span>
                  </a-menu-item>
                </a-menu>
              )}>
                <a-button>操作</a-button>
              </a-dropdown>
              <a-button onClick={() => {this.addVehicleVisible = true;this.formState.brandId = val.record.id}} style="margin-left:10px">添加车型</a-button>
            </div>
          )
        }
      }],
      innerColumns: [{
        title: '车型',
        dataIndex: 'name',
        key: 'name',
      }, {
        title: '版本',
        dataIndex: 'category',
        key: 'category',
      }, {
        title: '车图',
        dataIndex: 'image',
        key: 'image',
        customRender: (val) => {
          return <img src={val.value && val.value.url || ''} style="width: 40px" onClick={() => this.showImg(val.value.url)} />
        }
      }, {
        title: '排序',
        dataIndex: 'displayOrder',
        key: 'displayOrder',
      }, {
        title: '操作',
        dataIndex: 'operation',
        key: 'operation',
        customRender: (val) => {
          return (
            <div>
              <a-button onClick={() => this.editVehicle(val.record )}>编辑</a-button>
              <a-button onClick={() => this.editVehicle(val.record, 'del' )} style="margin-left:10px">删除</a-button>
            </div>
          )
        }
      }]
    }
  },
  created() {
    this.getVehicleBrands()
  },
  methods: {
    showImg(url) {
      console.log(url)
      this.isShow = true
      this.imgUrl = url
    },

    editVehicle({id, name, image, category, brand, displayOrder}, isDel) {
      if (isDel) {
        editVehicle({variables: {id, isDeleted: true}}).then(() => {
          this.handleCancel()
          this.getVehicleBrands()
        }).catch(err => {
          console.log(err)
        })
        return
      }
      this.formState = {
        id,
        name,
        category,
        displayOrder,
        brandId: brand.id
      }
      if (image) {
        this.formState.image = image.url
        this.formState.imageId = image.id
      }

      this.addVehicleVisible = true
    },

    editVehicleBrand({id, name, logo, displayOrder, isPublished}, type) {
      if (type == 'del') {
        this.handleOk({id, isDeleted: true}, 'vehicleBrandForm')
      } else if (type == 'publish') {
        this.handleOk({id, isPublished: !isPublished}, 'vehicleBrandForm')
      } else {
        this.formState = {
          id,
          name,
          displayOrder,
          logo: logo.url,
          logoId: logo.id
        }
        this.addVehicleBrandVisible = true
      }
    },

    getVehicleBrands() {
      getVehicleBrands().then(res => {
        if (res.errors) {
          this.$message.error(res.errors[0].message)
          return
        }
        let dataSource = res.data.vehicleBrands
        dataSource.forEach(e => e.innerData = e.vehicles)
        this.dataSource = dataSource
      })
    },

    handleOk(formState, form) {
      if (form == 'vehicleBrandForm') {
        editVehicleBrand({variables: formState}).then(() => {
          this.handleCancel()
          this.getVehicleBrands()
        }).catch(err => {
          console.log(err)
        })
      } else if (form == 'vehicleForm') {
        formState.isPublished = true
        editVehicle({variables: formState}).then(() => {
          this.handleCancel()
          this.getVehicleBrands()
        }).catch(err => {
          console.log(err)
        })
      }
      // console.log(this.vehicleBrandForm)
      
    },

    handleCancel() {
      this.addVehicleBrandVisible = false
      this.addVehicleVisible = false
      this.vehicleBrandId = ''
      this.formState = {}
    },
  },
}
</script>
<style lang="scss" scoped>
</style>