<template>
    <a-modal :visible="visible" title="添加达人" destroyOnClose :maskClosable="false"  @ok="handleOk" @cancel="handleCancel">
      <a-form
        ref="form"
        :model="formState"
        :validateOnRuleChange="false"
        name="form"
        :label-col="{ span: 5 }"
        :wrapper-col="{ span: 19 }"
        autocomplete="off"
        scrollToFirstError
      >
        <a-form-item
          label="昵称"
          name="nickname"
          :rules="[{ required: true, message: '输入达人昵称' }]"
        >
          <a-input :disabled="formState.id" v-model:value="formState.nickname" name="nickname" />
        </a-form-item>
        <a-form-item
          label="手机号"
          name="phoneNum"
          :rules="[{ required: true, message: '输入达人手机号' }]"
        >
          <a-input :disabled="formState.id" v-model:value="formState.phoneNum" name="phoneNum" />
        </a-form-item>
        <a-form-item
          label="欢迎语"
          name="referralWelcomeMsg"
        >
          <a-textarea auto-size v-model:value="formState.referralWelcomeMsg" name="referralWelcomeMsg" />
        </a-form-item>
      </a-form>
    </a-modal>
  </template>
  <script>
  import { ref, watch } from 'vue';
  export default {
    name: 'vehicleBrandForm',
    props: ['visible', 'formData'],
    setup(props) {
      const formState = ref(props.formData)
      watch(() => props.formData, (newVal) => formState.value = newVal)
      return {
        formState,
        form: ref(null),
      };
    },
    
    methods: {
      handleOk() {
        this.form.validateFields().then(res => {
          let formState = Object.assign(this.formState, res)
          this.$emit('formOk', formState)
        }).catch(err => {
          console.error(err)
          this.$message.error(err.errorFields[0].errors[0])
        })
      },
      handleCancel() {
        this.$emit('formCancel')
      },
    },
  
  }
  </script>