<template>
  <div>
    <div class="operations">
      <div style="flex:1">
        <a-select
          ref="select"
          :options="userTypes"
          :field-names="{ label: 'value', value: 'name'}"
          optionFilterProp="name"
          v-model:value="userType"
          style="width: 200px"
          @change="userTypeChange"
        />
      </div>
    </div>
    <GigaTable :gData="dataSource" :gColumns="columns" />
  </div>
</template>
<script>
import { getSpecialUsers, getChoice } from '../http/api'
import GigaTable from '../components/GigaTable.vue'
export default {
  components: {
    GigaTable,
  },
  data() {
    return {
      userType: 'giga_service_manager',
      userTypes: [],
      dataSource: [],
      columns: [{
        title: '部门',
        dataIndex: 'department',
        key: 'department'
      }, {
        title: '姓名',
        dataIndex: 'name',
        key: 'name'
      }]
    }
  },
  created() {
    this.getSpecialUsers()
    this.getChoice()
  },
  methods: {
    userTypeChange() {
      this.getSpecialUsers()
    },
    handleCancel() {
      this.showForm = false
      this.formState = {}
    },
    getChoice() {
      getChoice().then(res => {
        console.log(res)
        if (res.errors) {
          this.$message.error(res.errors[0].message)
          return
        }
        let userTypes = res.data.choice.userTypes
        this.userTypes = userTypes
      })
    },
    getSpecialUsers() {
      getSpecialUsers({variables: {userType: this.userType}}).then(res => {
        console.log(res)
        if (res.errors) {
          this.$message.error(res.errors[0].message)
          return
        }
        let dataSource = res.data.specialUsers
        this.dataSource = dataSource
      })
    }
  },
}
</script>
<style lang="scss" scoped>
  
</style>